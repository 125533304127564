import React from 'react';
import {Route} from "react-router-dom";
import Layout from "./Layout";

export default (
    {
        children,
        ...props
    }) => {

    return (
        <Layout>
            <Route {...props}>
                {children}
            </Route>
        </Layout>
    );
}