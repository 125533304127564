import {
    CLI_GROUPS as PREFIX,
} from '../const';

const initialState = {
    list: [],
    loading: true,
    selectedId: null
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {list}}) => ({...state, list: list}),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    ['SET_SELECTED_ID' + PREFIX]: (state, {payload}) => ({...state, selectedId: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}