import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {List, Form, Select, InputNumber, Input, Spin, Row, Col, Typography, Table} from "antd";
import {CustomFormItem} from '../../../components/base/Form/CustomFormItem';
import { ConsoleSqlOutlined } from '@ant-design/icons';


const {Title} = Typography;
const defCodes = [480, 486, 503, 403, 487];


export default (
    {
        ivrTypes,
        currentIvrId,
        langPacksList,
        isIvrEditable,
        forwardedRefs,
        ivrSoundsList,
        currentActionId,
        ivrActionOrderedList,
        ivrTypesRandomSoundFolders,
        ivrTypesStructuredSoundFolders,
        setIvrActionOrderedList,
    }
) => {

    const formRefs = useRef({});

    const [initFormValueState, setInitFormValueState] = useState({});

    // const useOnceCall = (callback, condition = true) => {
    //     const isCalledRef = useRef(false);
        
    //     useEffect(() => {
    //         if (condition && !isCalledRef.current) {
    //             isCalledRef.current = true;
    //             callback();
    //         }
    //     }, [callback, condition]);
    // };

    // useOnceCall(() => {
    //     const initFormValue = ivrActionOrderedList.reduce((result, item, idx) => {
    //         result[idx] = item.params|| {};
    //         return result;
    //     }, {});
    
    //     setInitFormValueState(initFormValue);
    // }, ivrActionOrderedList.length);

    useEffect(() => {
        if (ivrActionOrderedList.length && !isIvrEditable) {
            const initFormValue = ivrActionOrderedList.reduce((result, item, idx) => {
                result[idx] = item.params|| {};
                return result;
            }, {});
        
            setInitFormValueState(initFormValue);
        }
    }, [isIvrEditable, ivrActionOrderedList]);

    const langPacksListSelectData = useMemo(() => {
        return langPacksList.map(item => ({label: item.dir_name, value: item.dir_name}));
    }, [langPacksList]);

    const ivrSoundsListSelectData = useMemo(() => {
        return ivrSoundsList.map(item => ({label: item.filename, value: item.id}));
    }, [ivrSoundsList]);

    const ivrTypesRandomFolderListData = useMemo(() => {
        return ivrTypesRandomSoundFolders.map(item => ({label: `${item.name} (${item.files})`, value: item.name}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ivrTypesRandomSoundFolders]);
    
    const ivrTypesStructuredFolderListData = useMemo(() => {
        return ivrTypesStructuredSoundFolders.map(item => ({label: `${item.name} (${item.subdirs})`, value: item.name}));
    }, [ivrTypesStructuredSoundFolders]);
             
    const ivrSoundData = ivrSoundsListSelectData.map((sound, index) => {
        return {key: index.toString(), ...sound}
    })

    const ivrStructuredSoundsListSelectData = useMemo(() => {
        // wtf?
        // return ivrTypesStructuredSoundFolders.map(item => ({label: item.name+'.wav', value: item.name}));
        // select with optGroup
        return ivrTypesStructuredSoundFolders.map(item => ({value: item.name, structure: item?.structure.map(inner => ({label: inner+'.wav', value: inner}) ) }));
    }, [ivrTypesStructuredSoundFolders]);

    const [selectedRowKeys, setSelectedRowKeys] = useState(null);
    const [selectedRandomSounds, setSelectedRandomSounds] = useState(null);
    const [selectedStructuredRowKeys, setSelectedStructuredRowKeys] = useState(null);
    const [selectedStructuredSounds, setSelectedStructuredSounds] = useState(null);
    const [formStateValue, setFormStateValue] = useState({});
    const [prevIvrId, setPrevIvrId] = useState(null);

    const [fileListFilter, setFileListFilter] = useState(null);
    const [fileStructuredListFilter, setFileStructuredListFilter] = useState(null);

    const firstStructuredFolder = useMemo(() => {
        return ivrTypesStructuredSoundFolders.length && ivrTypesStructuredSoundFolders[0]?.name
    }, [ivrTypesStructuredSoundFolders]);

    const [ivrStucturedFolderValue, setIvrStuctureFolderValue] = useState(firstStructuredFolder);

    const ivrStructuredSoundData = useMemo(() => {
        return ivrStructuredSoundsListSelectData.find(folderData => folderData.value === ivrStucturedFolderValue)?.structure;
    }, [ivrStucturedFolderValue, ivrStructuredSoundsListSelectData]);

    useEffect(() => {
        setPrevIvrId(currentIvrId);
    }, [currentIvrId])

    useEffect(() => {
        ivrActionOrderedList.forEach((item) => {
            const currentType = ivrTypes.find(type => type.name === item.action_id);

            if (formRefs?.current?.[item.id]) {
                const fieldsValues = formRefs?.current?.[item.id].getFieldsValue();
                const fieldsWithDefaults = Object.keys(fieldsValues).reduce((acc, key) => {
                    if (fieldsValues[key] === undefined) {
                        acc[key] = currentType?.params_default && currentType.params_default?.[key];

                        if (key === "repetitions") {
                            acc[key] = +acc[key];
                        }
                    }
                    return acc
                }, {});
                const multipleSoundList = item?.sound_list && item.sound_list ? {sound_list: item.sound_list.map(sound => sound?.id)} : {};

                const initialValues = item?.params ? {
                    ...item.params,
                    ...multipleSoundList
                } : {};

                let initialFields = fieldsWithDefaults;

                if (Object.keys(initialValues).length) {
                    initialFields = initialValues;
                }

                formRefs.current[item.id].setFieldsValue(initialFields);
            }
        });

        if (!selectedRandomSounds) {
            const initSelectedSounds = ivrActionOrderedList.reduce((result, item) => {
                result[item.id] = item?.sound_list && item.sound_list.map(sound => sound.id) || [];
                return result;
            }, {});

            setSelectedRandomSounds(initSelectedSounds);
        }


        const initSelectedStructuredSounds = ivrActionOrderedList.reduce((result, item) => {
            result[item.id] = item?.params &&
                item?.params?.structured_sound_list &&
                item.params.structured_sound_list.length && 
                item.params.structured_sound_list.map(sound => sound) || [];
            return result;
        }, {});

        setSelectedStructuredSounds(initSelectedStructuredSounds);
        

        const initSelectedForm = ivrActionOrderedList.reduce((result, item) => {
            result[item.id] = item.params|| {};
            return result;
        }, {});

        setFormStateValue(initSelectedForm);

        if (prevIvrId !== currentIvrId) {
            setSelectedRandomSounds({});
        }

        const curretnStructuredFolder = ivrActionOrderedList.find((structure) => structure.id === currentActionId)?.params?.dir;

        if (curretnStructuredFolder) {
            setIvrStuctureFolderValue(curretnStructuredFolder);
        }
    }, [currentIvrId, currentActionId, ivrActionOrderedList]);


    const filteredIvrSoundData = useMemo(() => {
        return ivrSoundData.filter(sound => {
            return sound?.label.includes(fileListFilter) || fileListFilter === null
        });
    }, [ivrSoundData, fileListFilter]);

    const filteredIvrStructuredSoundData = useMemo(() => {
        if (ivrStructuredSoundData) {
            const ivrStructuredSoundDataIndexed = ivrStructuredSoundData.map((sound, index) => {
                return {key: index.toString(), ...sound};
            });
            
            return ivrStructuredSoundDataIndexed.filter(sound => {
                return sound?.label.includes(fileStructuredListFilter) || fileStructuredListFilter === null;
            });
        }
        
        return [];
    }, [currentIvrId, ivrStructuredSoundData, fileStructuredListFilter]);

    return (
        <Spin spinning={false}>
            <Title level={5}>Parameters</Title>

            {ivrActionOrderedList.map((item, itemIdx) => {
                if (!item.action_id) {
                    return null;
                }

                const currentParams = item?.params ? {...item.params} : {};
                const currentType = ivrTypes.find(type => type.action_id === item.action_id);
                const currentTypeValidation = currentType && currentType?.params_validation;

                const currentTitles = Object.keys(currentParams).reduce((acc, key) => {
                    acc[key] = {};
                    if (currentTypeValidation && currentTypeValidation.hasOwnProperty(key)) {
                        const title = currentTypeValidation[key]?.title;

                        acc[key].title = title;
                    }
                    acc[key].value = currentParams[key];
                    return acc;
                }, {});

                const currentSayDigitsNumberData = currentTypeValidation?.number?.enum && currentTypeValidation.number.enum.map(item => {
                    const sayDigitsLabels = {
                        "A": "Calling number",
                        "B": "Called number"
                    }
                    const label = sayDigitsLabels[item];

                    return {label: label, value: item}
                });

                const currentRingingGeneratingSideData = currentTypeValidation?.generating_side?.enum && currentTypeValidation.generating_side.enum.map(item => {
                    const generatingSideLabels = {
                        "client": "On client",
                        "server": "On server"
                    }
                    const label = generatingSideLabels[item];

                    return {label: label, value: item}
                });

                const multipleSoundList = item?.sound_list && item.sound_list ? {sound_list: item.sound_list.map(sound => sound?.id)} : {};

                const initialValues = item?.params ? {
                    ...item.params,
                    ...multipleSoundList
                } : {};

                const digitListData = [
                    {label: '0', value: '0'},
                    {label: '1', value: '1'},
                    {label: '2', value: '2'},
                    {label: '3', value: '3'},
                    {label: '4', value: '4'},
                    {label: '5', value: '5'},
                    {label: '6', value: '6'},
                    {label: '7', value: '7'},
                    {label: '8', value: '8'},
                    {label: '9', value: '9'},
                    {label: '#', value: '#'},
                    {label: '*', value: '*'}
                ];

                const randomSoundColumns = [
                    {
                        title: 'Sound',
                        dataIndex: 'label'
                    }
                ];

                const initialSoundRowKeys = filteredIvrSoundData.filter(sound => initialValues?.sound_list && initialValues.sound_list.includes(sound?.value)).map(sound => sound.key);
                const currentSelectedRowKey = selectedRowKeys ? selectedRowKeys[item.id] : null;

                const changeParamsInOrderedList = (item, params) => {
                    setIvrActionOrderedList(prev => {
                        return prev.map((prevItem) => {
                            if (item.id === prevItem.id) {
                                return {
                                    ...prevItem,
                                    params
                                }
                            }
        
                            return prevItem;
                        });
                    });
                };        

                const rowSelection = {
                    selectedRowKeys: currentSelectedRowKey || initialSoundRowKeys || [],
                    onChange: (selectedKeys, selectedRows) => {
                        let selectedState = {...selectedRandomSounds};

                        selectedState[item.id] = selectedRows.map(sound => sound.value);

                        const currentSelectedRowKeys = {...selectedRowKeys};
                        currentSelectedRowKeys[item.id] = selectedKeys;
                        setSelectedRowKeys(currentSelectedRowKeys);
                        setSelectedRandomSounds(selectedState);

                        // setIvrActionOrderedList(prev => {
                        //     return prev.map((prevItem) => {
                        //         if (item.id === prevItem.id) {
                        //             return {
                        //                 ...prevItem,
                        //                 params: {
                        //                     ...({...item.params, ...formStateValue[item.id]}), 
                        //                     sound_list: [...(item.id in selectedState ? selectedState[item.id] || [] : [])]
                        //                 }
                        //             }
                        //         }

                        //         return prevItem;
                        //     });
                        // });
                        changeParamsInOrderedList(item, {
                            ...({...item.params, ...formStateValue[item.id]}), 
                            sound_list: [...(item.id in selectedState ? selectedState[item.id] || [] : [])]
                        });
                    },
                    getCheckboxProps: (record) => ({
                        disabled: !isIvrEditable
                    })
                };

                const initialStructuredSoundRowKeys = filteredIvrStructuredSoundData && filteredIvrStructuredSoundData.filter(
                     sound => initialValues?.structured_sound_list && initialValues.structured_sound_list.includes(
                         sound?.value)).map(sound => sound.key);
                
                const currentSelectedStructuredRowKey = selectedStructuredRowKeys ? selectedStructuredRowKeys[item.id] : null;

                // console.log('whole sctructured sounds data=', filteredIvrStructuredSoundData);
                // console.log('initial sctructured row keys=', initialStructuredSoundRowKeys); 
                // console.log('current sctructured row key=', currentSelectedStructuredRowKey); 

                const rowStructuredSelection = {
                    selectedRowKeys: currentSelectedStructuredRowKey || initialStructuredSoundRowKeys || [],
                    onChange: (selectedKeys, selectedRows) => {
                        let selectedState = {...selectedStructuredSounds};
           
                        selectedState[item.id] = selectedRows.map(sound => sound.value);

                        const currentSelectedStructuredRowKeys = {...selectedStructuredRowKeys};
                        currentSelectedStructuredRowKeys[item.id] = selectedKeys;
                        setSelectedStructuredRowKeys(currentSelectedStructuredRowKeys);
                        setSelectedStructuredSounds(selectedState);

                        // setIvrActionOrderedList(prev => {
                        //     return prev.map((prevItem) => {
                        //         if (item.id === prevItem.id) {
                        //             return {
                        //                 ...prevItem,
                        //                 params: {
                        //                     ...({...item.params, ...formStateValue[item.id]}), 
                        //                     structured_sound_list: [...(item.id in selectedState ? selectedState[item.id] || [] : [])]
                        //                 }
                        //             }
                        //         }

                        //         return prevItem;
                        //     });
                        // });
                        changeParamsInOrderedList(item, {
                            ...({...item.params, ...formStateValue[item.id]}), 
                            structured_sound_list: [...(item.id in selectedState ? selectedState[item.id] || [] : [])]
                        });
                    },
                    getCheckboxProps: (record) => ({
                        disabled: !isIvrEditable
                    })
                };

                return (
                    <Form
                        ref={ref => {
                            formRefs.current[item.id] = ref;
                            forwardedRefs.current[item.id] = ref;
                        }}
                        style={{display: currentActionId === item.id ? 'block' : 'none'}}
                        layout={'horizontal'}
                        initialValues={initialValues}
                        onValuesChange={(_, values) => {
                            const currentStateForm = {...formStateValue};
                            currentStateForm[item.id] = {...currentStateForm[item.id], ...values};

                            setFormStateValue(currentStateForm);

                            setIvrActionOrderedList(prev => {
                                return prev.map((prevItem) => {
                                    if (item.id === prevItem.id) {
                                        return {
                                            ...prevItem,
                                            params: {
                                                ...values, 
                                                sound_list: [...(item.id in selectedRandomSounds ? selectedRandomSounds[item.id] || [] : [])],
                                                // structured_sound_list: [...(item.id in selectedStructuredSounds ? selectedStructuredSounds[item.id] || [] : [])]
                                            }
                                        };
                                    }

                                    return prevItem;
                                });
                            });
                        }}
                    >

                        {item.action_id === 'RejectCall' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>Rejection percent <small>(100% - ASR)</small></StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <OneRowItem>
                                            <CustomFormItem
                                                className="percent_input"
                                                name="percent"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Is required',
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    disabled={!isIvrEditable}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                />
                                            </CustomFormItem>
                                            <span className={"percent"}>%</span>
                                        </OneRowItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>

                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.hangup_codes?.title || 'SIP codes'}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <List style={{width: '100%'}}>
                                            {defCodes.map((item) => {
                                                return <List.Item>
                                                    <StyledSipListItem>

                                                        <CustomFormItem
                                                            className="percent_input"
                                                            label={item}
                                                            name={['hangup_codes', 'sip_' + item]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Is required',
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                disabled={!isIvrEditable}
                                                                step={1}
                                                            />
                                                        </CustomFormItem>

                                                        <span className={"percent"}>%</span>
                                                    </StyledSipListItem>
                                                </List.Item>
                                            })}
                                        </List>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>
                        }

                        {item.action_id === 'WaitBeforeConnect' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.before_min?.title || 'Delay before ringback'} <small>(0 - 60)</small></StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <CustomFormItem
                                            label={'from'}
                                            name={`before_min`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={0}
                                                max={60}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`before_max`}
                                            label={'to'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={0}
                                                max={60}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>

                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.ringing_min?.title || 'Ringback duration'} <small>(0 - 60)</small></StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`ringing_min`}
                                            label={'from'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={0}
                                                max={60}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`ringing_max`}
                                            label={'to'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={0}
                                                max={60}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>

                            <StyledFormSection>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <CustomFormItem
                                            label="Ringback generating side"
                                            name="generating_side"
                                            rules={[
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {
                                                        if (getFieldValue('generating_side') === "") {
                                                            return Promise.reject('Select generating side');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Select
                                                disabled={!isIvrEditable}
                                                options={currentRingingGeneratingSideData}
                                                placeholder="Select generating side"
                                                style={{width: '100%', maxWidth: 400}}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>

                        </>
                        }

                        {item.action_id === 'Playback' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.playback_min?.title || 'Playback'} <small>(1 - 7200)</small></StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`playback_min`}
                                            label={'from'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>

                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`playback_max`}
                                            label={'to'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.sound?.title || 'Sound'}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <CustomFormItem
                                            name="sound"
                                        >
                                            <Select
                                                disabled={!isIvrEditable}
                                                options={ivrSoundsListSelectData}
                                                placeholder="Select sound"
                                                style={{width: '100%', minWidth: 200, maxWidth: 400}}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>
                        }

                        {item.action_id === 'PlaybackLoop' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.repetitions?.title || ''}</StyledFormSectionTitle>
                                <Row gutter={10}>

                                    <Col>
                                        <CustomFormItem
                                            name={`repetitions`}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>

                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.sound?.title || 'Sound'}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <CustomFormItem
                                            name="sound"
                                        >
                                            <Select
                                                disabled={!isIvrEditable}
                                                options={ivrSoundsListSelectData}
                                                placeholder="Select sound"
                                                style={{width: '100%', minWidth: 200, maxWidth: 400}}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>
                        }

                        {item.action_id === 'Radio' &&
                        <StyledFormSection>
                            <StyledFormSectionTitle>{currentTitles?.radio_min?.title || 'Radio params'} <small>(1 - 7200)</small></StyledFormSectionTitle>
                            <Row gutter={10}>
                                <Col span={12}>
                                    <CustomFormItem
                                        label={'from'}
                                        name={`radio_min`}
                                    >
                                        <InputNumber
                                            disabled={!isIvrEditable}
                                            min={1}
                                            max={7200}
                                            step={1}
                                        />
                                    </CustomFormItem>
                                </Col>
                                <Col span={12}>
                                    <CustomFormItem
                                        label={'to'}
                                        name={`radio_max`}
                                    >
                                        <InputNumber
                                            disabled={!isIvrEditable}
                                            min={1}
                                            max={7200}
                                            step={1}
                                        />
                                    </CustomFormItem>
                                </Col>
                            </Row>
                        </StyledFormSection>
                        }

                        {item.action_id === 'Ringing' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.ringbacktone_min?.title || 'Ringbacktone params'} <small>(1 - 7200)</small></StyledFormSectionTitle>
                                <Row gutter={10}>

                                    <Col span={12}>
                                        <CustomFormItem
                                            label={'from'}
                                            name={`ringing_min`}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>

                                    <Col span={12}>
                                        <CustomFormItem
                                            label={'to'}
                                            name={`ringing_max`}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>

                                </Row>
                            </StyledFormSection>

                            <StyledFormSection>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <CustomFormItem
                                            label="Ringback generating side"
                                            name="generating_side"
                                            rules={[
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {
                                                        if (getFieldValue('generating_side') === "") {
                                                            return Promise.reject('Select generating side');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Select
                                                disabled={!isIvrEditable}
                                                options={currentRingingGeneratingSideData}
                                                placeholder="Select generating side"
                                                style={{width: '100%', maxWidth: 400}}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>
                        }

                        {item.action_id === 'SayDigits' &&
                        <StyledFormSection>
                            <StyledFormSectionTitle>{currentTitles?.deleted_digits?.title || 'Say digits params'}</StyledFormSectionTitle>
                            <Row gutter={10}>
                                <Col span={16}>
                                    <CustomFormItem
                                        label="Skipped digits from number"
                                        name="deleted_digits"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Is required',
                                            },
                                            // {
                                            //     max: 15,
                                            //     message: 'Too much digits'
                                            // },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    const sayDigitsPattern = '^(([0-9])|(1[0-5]))$';
                                                    const rule = new RegExp(`${sayDigitsPattern}`);
                                                    if (!rule.test(getFieldValue('deleted_digits'))) {
                                                        return Promise.reject('Type correct digits');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <InputNumber
                                            disabled={!isIvrEditable}
                                            placeholder="Type digits"
                                            style={{width: '100%', maxWidth: 400}}
                                        />
                                    </CustomFormItem>
                                    <CustomFormItem
                                        label="Language pack"
                                        name="language"
                                        rules={[
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (getFieldValue('language') === "") {
                                                        return Promise.reject('Select language');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Select
                                            disabled={!isIvrEditable}
                                            options={langPacksListSelectData}
                                            placeholder="Select language"
                                            style={{width: '100%', maxWidth: 400}}
                                        />
                                    </CustomFormItem>
                                    <CustomFormItem
                                        label="Number type"
                                        name="number"
                                        rules={[
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (getFieldValue('number') === "") {
                                                        return Promise.reject('Select number');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Select
                                            disabled={!isIvrEditable}
                                            options={currentSayDigitsNumberData}
                                            placeholder="Select number"
                                            style={{width: '100%', maxWidth: 400}}
                                        />
                                    </CustomFormItem>
                                </Col>
                            </Row>
                        </StyledFormSection>
                        }

                        {item.action_id === 'SendDTMF' &&
                        <StyledFormSection>
                            <StyledFormSectionTitle>{currentTitles?.digit?.title || 'DTMF Signal'} <small>(use "|" as separator)</small></StyledFormSectionTitle>
                            <Row gutter={10}>
                                <Col>
                                    <CustomFormItem
                                        disabled={!isIvrEditable}
                                        name={`digit`}
                                        rules={[
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    const sayDigitsPattern = currentTypeValidation?.digit?.pattern;
                                                    const rule = new RegExp(`${sayDigitsPattern}`);
 
                                                    if (!rule.test(value)) {
                                                        return Promise.reject('Digits does not match pattern');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                         {/* <Select
                                                disabled={!isIvrEditable}
                                                options={digitListData}
                                                placeholder="Select digit"
                                                style={{width: '100%', minWidth: 200, maxWidth: 400}}
                                            /> */}
                                        <Input
                                             disabled={!isIvrEditable}
                                        />
                                    </CustomFormItem>
                                </Col>
                            </Row>
                        </StyledFormSection>
                        }

                        {item.action_id === 'Silence' &&
                        <StyledFormSection>
                            <StyledFormSectionTitle>{currentTitles?.silence_min?.title || 'Silence params'} <small>(1 - 7200)</small></StyledFormSectionTitle>
                            <Row gutter={10}>

                                <Col span={12}>
                                    <CustomFormItem
                                        name={`silence_min`}
                                        label={'from'}
                                    >
                                        <InputNumber
                                            disabled={!isIvrEditable}
                                            min={1}
                                            max={7200}
                                            step={1}
                                        />
                                    </CustomFormItem>
                                </Col>

                                <Col span={12}>
                                    <CustomFormItem
                                        label={'to'}
                                        name={`silence_max`}
                                    >
                                        <InputNumber
                                            disabled={!isIvrEditable}
                                            min={1}
                                            max={7200}
                                            step={1}
                                        />
                                    </CustomFormItem>
                                </Col>

                            </Row>
                        </StyledFormSection>
                        }

                        {/* {item.action_id === 'Wait' &&
                        <StyledFormSection>
                            <StyledFormSectionTitle>{currentTitles?.wait_min?.title || 'Wait'} <small>(0 - 7200)</small></StyledFormSectionTitle>
                            <Row gutter={10}>

                                <Col span={12}>
                                    <CustomFormItem
                                        label={'from'}
                                        name={`wait_min`}
                                    >
                                        <InputNumber
                                            disabled={!isIvrEditable}
                                            min={0}
                                            max={7200}
                                            step={1}
                                        />
                                    </CustomFormItem>
                                </Col>

                                <Col span={12}>
                                    <CustomFormItem
                                        label={'to'}
                                        name={`wait_max`}
                                    >
                                        <InputNumber
                                            disabled={!isIvrEditable}
                                            min={1}
                                            max={7200}
                                            step={1}
                                        />
                                    </CustomFormItem>
                                </Col>

                            </Row>
                        </StyledFormSection>
                        } */}

                        {item.action_id === 'PlayRandomFile' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.playback_min?.title || 'Playback'} <small>(1 - 7200)</small></StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`playback_min`}
                                            label={'from'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>

                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`playback_max`}
                                            label={'to'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.dir?.title || ''}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <CustomFormItem
                                            name="dir"
                                        >
                                            <Select
                                                disabled={!isIvrEditable}
                                                options={ivrTypesRandomFolderListData}
                                                placeholder="Select directory"
                                                style={{width: '100%', minWidth: 200, maxWidth: 400}}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>}

                        {item.action_id === 'PlayRandomFileLoop' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.repetitions?.title || ''}</StyledFormSectionTitle>
                                <Row gutter={10}>

                                    <Col>
                                        <CustomFormItem
                                            name={`repetitions`}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.dir?.title || ''}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <CustomFormItem
                                            name="dir"
                                        >
                                            <Select
                                                disabled={!isIvrEditable}
                                                options={ivrTypesRandomFolderListData}
                                                placeholder="Select directory"
                                                style={{width: '100%', minWidth: 200, maxWidth: 400}}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>
                        }

                        {item.action_id === 'PlayRandomSound' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.playback_min?.title || 'Playback'} <small>(1 - 7200)</small></StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`playback_min`}
                                            label={'from'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>

                                    <Col span={12}>
                                        <CustomFormItem
                                            name={`playback_max`}
                                            label={'to'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={1}
                                                max={7200}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.sound?.title || 'Sound'}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <Input
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                setFileListFilter(value);
                                            }}
                                            value={fileListFilter}
                                            disabled={!isIvrEditable}
                                            placeholder="Type sound name..."
                                        />
                                        <CustomFormItem
                                            name="sound_list"
                                        >
                                            <Table 
                                                rowSelection={rowSelection}
                                                columns={randomSoundColumns} 
                                                dataSource={filteredIvrSoundData}
                                                disabled={true}
                                                selections={selectedRandomSounds ? selectedRandomSounds[item.id] : []}
                                                pagination={{ pageSize: 10, disabled: !isIvrEditable }} 
                                                scroll={{ y: 240 }}
                                                size="small"
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>

                        </>}

                        {item.action_id === 'PlayRandomSoundLoop' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.repetitions?.title || ''}</StyledFormSectionTitle>
                                <Row gutter={10}>

                                    <Col>
                                        <CustomFormItem
                                            name={`repetitions`}
                                        >
                                            <InputNumber
                                                disabled={!isIvrEditable}
                                                min={currentTypeValidation?.repetitions.minimum}
                                                max={currentTypeValidation?.repetitions.maximum}
                                                step={1}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>

                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.sound?.title || 'Sound'}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <Input
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                setFileListFilter(value);
                                            }}
                                            value={fileListFilter}
                                            disabled={!isIvrEditable}
                                            placeholder="Type sound name..."
                                        />
                                        <CustomFormItem
                                            name="sound_list"
                                        >
                                            <Table 
                                                rowSelection={rowSelection}
                                                columns={randomSoundColumns} 
                                                dataSource={filteredIvrSoundData}
                                                disabled={true}
                                                selections={selectedRandomSounds ? selectedRandomSounds[item.id] : []}
                                                pagination={{ pageSize: 10, disabled: !isIvrEditable }} 
                                                scroll={{ y: 240 }}
                                                size="small"
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>}

                        {item.action_id === 'SayHello' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.dir?.title || ''}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <CustomFormItem
                                            name="dir"
                                        >
                                            <Select
                                                disabled={!isIvrEditable}
                                                options={ivrTypesRandomFolderListData}
                                                placeholder="Select directory"
                                                style={{width: '100%', minWidth: 200, maxWidth: 400}}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>}


                        {item.action_id === 'PlayFileFromRandomFolder' &&
                        <>
                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.dir?.title || ''}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <CustomFormItem
                                            name="dir"
                                        >
                                            <Select
                                                disabled={!isIvrEditable}
                                                onChange={(folder => {
                                                    const defaultFolder = !!initFormValueState[itemIdx] ? initFormValueState[itemIdx]?.dir : null;
                                                    const defaultStructuredSoundList = !!initFormValueState[itemIdx] ? initFormValueState[itemIdx]?.structured_sound_list : [];

                                                    const defaultStructuredRowKeys = filteredIvrStructuredSoundData.filter(sound => defaultStructuredSoundList.includes(sound.value)).map(sound => sound.key);

                                                    if (folder !== defaultFolder) {
                                                        changeParamsInOrderedList(item, {
                                                            ...item.params,
                                                            dir: folder,
                                                            structured_sound_list: []
                                                        });

                                                        setSelectedStructuredRowKeys([]);
                                                        setSelectedStructuredSounds([]);
                                                    } else {
                                                        changeParamsInOrderedList(item, {
                                                            ...item.params,
                                                            dir: folder,
                                                            structured_sound_list: defaultStructuredSoundList
                                                        });

                                                        setSelectedStructuredRowKeys(defaultStructuredRowKeys);
                                                        setSelectedStructuredSounds(defaultStructuredSoundList);
                                                    }               

                                                    setIvrStuctureFolderValue(folder);
                                                })}
                                                options={ivrTypesStructuredFolderListData}
                                                placeholder="Select directory"
                                                style={{width: '100%', minWidth: 200, maxWidth: 400}}
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>

                            <StyledFormSection>
                                <StyledFormSectionTitle>{currentTitles?.sound?.title || 'Sound'}</StyledFormSectionTitle>
                                <Row gutter={10}>
                                    <Col>
                                        <Input
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                setFileStructuredListFilter(value);
                                            }}
                                            value={fileStructuredListFilter}
                                            disabled={!isIvrEditable}
                                            placeholder="Type sound name..."
                                        />
                                        <CustomFormItem
                                            name="structured_sound_list"
                                        >
                                            <Table 
                                                rowSelection={rowStructuredSelection}
                                                columns={randomSoundColumns}
                                                dataSource={filteredIvrStructuredSoundData}
                                                // dataSource={structureList}
                                                disabled={true}
                                                selections={selectedStructuredSounds ? selectedStructuredSounds[item.id] : []}
                                                pagination={{ pageSize: 10, disabled: !isIvrEditable }} 
                                                scroll={{ y: 240 }}
                                                size="small"
                                            />
                                        </CustomFormItem>
                                    </Col>
                                </Row>
                            </StyledFormSection>
                        </>}

                    </Form>
                )
            })}
        </Spin>
    )
};


const StyledFormSection = styled.div`
    & + * {
        padding-top: 0;
    }
`;

const StyledFormSectionTitle = styled.p`
    padding-bottom: 10px;
    margin-bottom: 0;
    font-weight: 500;
`;

const StyledSipListItem = styled.div`
    align-items: center;
    display: flex;
    width 100%;

    .sip-code {
        font-weight: 600;
        width 70px;
    }
    && span.percent {
        margin-left: 5px;
    }
`;
const OneRowItem = styled.div`
    && {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        margin-bottom: 20px;
    }
    && span.percent {
        margin-left: 5px;
    }
`;