import {LANGPACKS as PREFIX} from '../const/';
import {LANGPACK_FETCH_LIST_API} from "../const/apiMethods";
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';

const setLoading = createAction('SET_LOADING_' + PREFIX);
const setLangPacksList = createAction('SET_ITEMS_' + PREFIX);


export const getLangPacksList = (filter) => (dispatch) => {
    dispatch(setLoading(true));
    api(LANGPACK_FETCH_LIST_API, {filter})
        .then((r) => {
            if (!r || !r?.langpack_list) {
                return;
            }

            dispatch(setLangPacksList({list: r.langpack_list}));
        })
        .finally(() => dispatch(setLoading(false)));
};