import {IVR as PREFIX} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {IVR_LIST_API, IVR_ACTION_TYPE_LIST_API} from "../const";

const setLoading = createAction('SET_LOADING_' + PREFIX);
const setIvrList = createAction('SET_ITEMS_' + PREFIX);
const setIvrTypes = createAction('SET_TYPES_' + PREFIX);
const setSelectedIvrId = createAction('SET_SELECTED_ID' + PREFIX);


export const getIvrList = (filter = {}) => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_LIST_API, {filter})
        .then((r) => {
            if (!r?.ivr_list) {
                return;
            }
            dispatch(setIvrList({
                list: r.ivr_list
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};


export const getIvrTypes = () => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_ACTION_TYPE_LIST_API)
        .then(({ivr_action_type_list, random_sound_dir_list, structured_sound_dir_list}) => {
            if (ivr_action_type_list === undefined) {
                return;
            }
            dispatch(setIvrTypes({
                types: ivr_action_type_list,
                random_sound_folders: random_sound_dir_list,
                structured_sound_folders: structured_sound_dir_list
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};


export const selectIvrId = (id) => (dispatch) => {
    dispatch(setSelectedIvrId(id));
};