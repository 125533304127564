import axios from 'axios';
import store from '../store/index';
import {setAuth, setMaintenanceMode} from '../actions/auth';
import {message} from 'antd';


const baseUrl = process.env.REACT_APP_API_URL;
const codeError = {
    1: 'Authentication error',      // authentication
    2: 'No Permission',             // authorization
    3: 'Validation error',          // validation
    4: 'Data error',                // data
    DEFAULT: 'Server error'
};



// login instance

const _loginAxios = () => {

    const instanceAxios = axios.create({
        baseURL: baseUrl,
        headers: {
            "Content-Type": 'application/json'
        }
    });

    instanceAxios.interceptors.response.use(function (response) {

        if (response.data.error) {
            return null;
        }

        return response;
        
    }, function (error) {
        
        if (error.response.status === 423) {
            message.error("Operation failed. Try again in few seconds");
            return null;
        }

        if (error.response.status === 503) {
            store.dispatch( setMaintenanceMode(true) );
            return null;
        }

        return Promise.reject(error);
    });

    return instanceAxios;
};



// version check instance

const _checkVersionAxios = () => {
    const instanceAxios = axios.create({
        headers: {
            "Content-Type": 'application/json'
        }
    });

    instanceAxios.interceptors.response.use(
        function (response) {
            if (response.data.error || response.status === 404) {
                return null;
            }
            return response;
        }, function (error) {
            return Promise.reject(error);
        }
    );

    return instanceAxios;
};



// common request instance

const _requestAxios = (headers = false) => {

    const url = headers ? `${baseUrl}/headers` : baseUrl;
    let instanceAxios = axios.create({
        baseURL: url,
        headers: {
            "Content-Type": 'application/json'
        },
        timeout: 3600000
    });


    /* Request Interceptor */
    instanceAxios.interceptors.request.use(function (config) {

        const session_key = localStorage.getItem('api_key');
        if(session_key) {
            config.baseURL = `${url}?method=${config.data.method}`;
            config.headers = {...config.headers, 'Session-Key': session_key}
        } else {
            unsetAuth();
        }

        return config;

    }, function (error) {
        return Promise.reject(error);
    });


    /* Response Interceptor */
    instanceAxios.interceptors.response.use(function (response) {

        // const responseError = _.get(response, 'data.error');
        const responseError = response?.data?.error;

        if (responseError) {
            if (responseError.code === 1 || responseError.code === 2) {
                unsetAuth();
            } else {
                message.error(codeError[responseError.code] || codeError.DEFAULT);
            }
            return null;
        }
        return response;

    }, function (error) {

        // on cancelled request
        if ( axios.isCancel(error) ) {
            return error;
        }

        if (error && !error.response) {
            message.error("Something went wrong", 3);
        }

        const {response} = error;
        
        if (response.status === 423) {
            message.error("Operation failed. Try again in few seconds");
            return null;
        }

        // on enabled maintenance
        if (response.status === 503) {
            store.dispatch( setMaintenanceMode(true) );
            return null;
        }

        // const responseError = _.get(response, 'data.error');
        const responseError = response?.data?.error;

        if (responseError) {
            if (responseError.code === 1) {
                unsetAuth();
                return null;
            }

            if (responseError.code === 2) {
                message.error("No authorization permission");
                return null;
            }
        }

        return Promise.reject(error);

    });

    return instanceAxios;
};



// form data request instance

const _requestFormDataAxios = () => {

    const url = baseUrl;
    let instanceAxios = axios.create({
        baseURL: url,
        headers: {
            "Content-Type": 'multipart/form-data'
        },
        timeout: 3600000
    });


    /* Request Interceptor */
    instanceAxios.interceptors.request.use(function (config) {

        const configCopy = {...config};
        const session_key = localStorage.getItem('api_key');
        if(session_key) {
            configCopy.baseURL = `${url}?method=${configCopy.data.method}`;
            configCopy.headers = {...configCopy.headers, 'Session-Key': session_key}
        } else {
            unsetAuth();
        }

        const bodyFormData = new FormData();

        if (configCopy?.data?.params?.upload_file_list?.length) {
            
            for (const file of configCopy.data.params.upload_file_list) {
                if (file.filename) {
                    bodyFormData.append('file', file, file.filename);
                } else {
                    bodyFormData.append('file', file);
                }
            }
            
        }

        delete configCopy.data.params.upload_file_list;

        bodyFormData.append( 'json', JSON.stringify(configCopy.data) );

        configCopy.data = bodyFormData;

        return configCopy;

    }, function (error) {
        return Promise.reject(error);
    });

    return instanceAxios;
};


const unsetAuth = () => {
    store.dispatch( setAuth(false) );
    if (localStorage.getItem('api_key'))
        localStorage.removeItem('api_key');
};



export const loginAxios = _loginAxios();
export const requestAxios = _requestAxios();
export const requestFormDataAxios = _requestFormDataAxios();
export const requestAxiosWithHeaders = _requestAxios(true);
export const checkVersionAxios = _checkVersionAxios();