/* AUTH */

export const REFERENCES_SET_LISTS = 'REFERENCES_SET_LISTS';
export const REFERENCES_SET_LOADING = 'REFERENCES_SET_LOADING';

export const SET_PERMISSION = 'SET_PERMISSION';
export const SET_TWO_FACTOR_AUTH = 'SET_TWO_FACTOR_AUTH';
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE';

export const SET_USER_DATA = 'accounts::SET_ACCOUNT_DATA';
export const SET_AUTH_DATA = 'auth::SET_AUTH_DATA';
export const SET_AUTH_LOADING = 'auth::SET_LOADING';

export const SET_ROLES = 'roles::SET_ROLES';

export const SET_GROUP_ROUTING = 'routing::SET_GROUP_ROUTING';

export const IVR = 'IVR';
export const CLI_GROUPS = 'CLI_GROUPS';
export const ROUTING = 'ROUTING';
export const FOLDERS = 'FOLDERS';
export const STRUCTURED_FOLDERS = 'STRUCTURED_FOLDERS';

export const SOUNDS = 'SOUNDS';
export const LANGPACKS = 'LANGPACKS';