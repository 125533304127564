import React, {useRef} from "react";
import {Button, List} from "antd";
import styled from "styled-components";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import CustomAudioPlayer from "../../components/CustomAudioPlayer/CustomAudioPlayer";


export default (
    {
        data,
        loadingItems,
        onClickRemove,
        onClickReplace,
        onClickDownload,
    }
) => {

    const audioRefs = useRef(new Map());
    const playedSoundId = useRef(null);


    return (
        <List
            bordered
        >
            {data.map(item => {

                return (
                    <List.Item key={item.id}>
                        <IvrAudio className="ivr-audio">
                            <div className="ivr-audio__title">{item.filename}</div>

                            <CustomAudioPlayer
                                ref={(ref) => audioRefs.current.set(item.id, ref)}
                                src={`${item.url_source}?${item.filename}`}
                                onPlay={() => {
                                    if (playedSoundId.current && playedSoundId.current === item.id) {
                                        return;
                                    }

                                    if (playedSoundId.current) {
                                        const currentAudioRef = audioRefs.current.get(playedSoundId.current);
                                        if (currentAudioRef) {
                                            currentAudioRef.audio.current.pause();
                                        }
                                    }
                                    playedSoundId.current = item.id;
                                }}
                            />

                            <div className="ivr-audio__actions">
                                <Button
                                    style={{marginRight: 8}}
                                    loading={loadingItems[item.id]}
                                    buttonStyle="default"
                                    onClick={() => onClickDownload(item.id, item.filename)}
                                    icon={<DownloadOutlined />}
                                />

                                <Button
                                    style={{marginRight: 8}}
                                    onClick={() => onClickReplace(item.id)}
                                    buttonStyle="secondary"
                                >
                                    Replace by sound
                                </Button>

                                <Button
                                    danger
                                    disabled={item?.cannot_be_deleted}
                                    onClick={() => onClickRemove(item.id)}
                                    icon={<DeleteOutlined />}
                                />

                            </div>
                        </IvrAudio>
                    </List.Item>
                )
            })}
        </List>
    )
}


const IvrAudio = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    
    .ivr-audio__actions {
        flex-shrink: 0;
        margin-left: auto;
    }
    
    .ivr-audio__title {
        width: 350px;
        padding-right: 30px;
        word-break: break-all;
    }
`;