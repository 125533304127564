import {
    SET_AUTH_DATA,
    SET_USER_DATA,
    SET_AUTH_LOADING,
    SET_PERMISSION,
    SET_TWO_FACTOR_AUTH,
    SET_MAINTENANCE_MODE,
} from "../const/actionTypes";

import {
    LOCAL_STORAGE_API_KEY,
    LOCAL_STORAGE_USER_DATA
} from "../const/localStorageKeys";


const isAuth = !!localStorage.getItem(LOCAL_STORAGE_API_KEY);
const savedUserData = localStorage.getItem(LOCAL_STORAGE_USER_DATA)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA))
    : null;
const role = savedUserData?.session?.account_user?.role_list?.[0] || null;


const initialState = {
    auth: isAuth && savedUserData,
    loading: false,
    userData: savedUserData,
    permissions: [],
    role: role,
    
    secondAuthType: null,
    secondAuthLink: null,
    secondAuthEmail: null,
    
    maintenanceMode: false,
};

const handlers = {
    
    [SET_AUTH_DATA]: (state, {payload}) => ({
        ...state,
        auth: payload,
        loading: false,
        permissions: [],
        secondAuthType: null,
        secondAuthLink: null,
        secondAuthEmail: null,
    }),

    [SET_AUTH_LOADING]: (state, {payload}) => ({
        ...state, 
        loading: payload
    }),

    [SET_USER_DATA]: (state, {payload}) => ({
        ...state,
        userData: payload,
        loading: false
    }),

    [SET_TWO_FACTOR_AUTH]: (state, {payload}) => ({
        ...state, 
        ...payload
    }),

    [SET_PERMISSION]: (state, {payload}) => ({
        ...state, 
        permissions: payload
    }),

    [SET_MAINTENANCE_MODE]: (state, {payload}) => ({
        ...state, 
        maintenanceMode: payload
    }),

    DEFAULT: state => state
};

const auth = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};

export default auth; 