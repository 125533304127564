import React from 'react';
import BaseRoute from "./BaseRoute";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {DEFAULT_NOT_AUTH_PATH} from "../const";


const AuthRoute = ({auth, ...props}) => {

    if (!auth) {
        return <Redirect to={DEFAULT_NOT_AUTH_PATH} />;
    }

    return <BaseRoute {...props} />
};

const mapState = ({auth})=> ({
    auth: auth.auth,
});

export default connect(mapState)(AuthRoute);