import React, {useMemo} from 'react'
import {Form, Input, Select} from "antd";
import debounce from "lodash/debounce";
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';
import {handleFilterOption, handleFilterSort} from '../../utils';


export default (
    {
        ivrList,
        setFilter,
        tprefixGroupList,
        cliGroupsList,
        ...props
    }
) => {


    const [form] = Form.useForm();

    const handleValuesChange = async (_, params) => {
        try {
            await form.validateFields();
            setFilter(params);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    const ivrListOptionsData = useMemo(() => {
        return ivrList.map(item => ({value: item.id, label: item.name}));
    }, [ivrList]);


    const cliGroupsListOptionsData = useMemo(() => {
        return cliGroupsList.map(item => ({value: item.cg_key, label: item.name}));
    }, [cliGroupsList]);


    const tprefixListOptionsData = useMemo(() => {
        return tprefixGroupList.map(item => ({value: item, label: item}))
    }, [tprefixGroupList]);

    return (
        <>
            <Form
                formItemLayout={'inline'}
                layout={'inline'}
                form={form}
                onValuesChange={debounce(handleValuesChange, 500)}
            >
                <CustomFormItem name={'tprefix'}>
                    <Select
                        style={{ width: 200 }}
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        placeholder="Tech prefix"
                        filterOption={(input, option) => handleFilterOption(input, option, 'label')}
                        filterSort={(optionA, optionB) =>handleFilterSort(optionA, optionB, 'label')}
                        options={tprefixListOptionsData}
                    />
                </CustomFormItem>

                <CustomFormItem name={'cg_key'}>
                    <Select
                        style={{ width: 200 }}
                        placeholder="CLI group"
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => handleFilterOption(input, option, 'label')}
                        filterSort={(optionA, optionB) =>handleFilterSort(optionA, optionB, 'label')}
                        options={cliGroupsListOptionsData}
                    >
                    </Select>
                </CustomFormItem>

                <CustomFormItem
                    name={'cli'}
                    rules={[
                        {
                            max: 40,
                            message: 'The maximum is 40 characters'
                        }
                    ]}
                >
                    <Input
                        style={{minWidth: '200px'}}
                        placeholder="CLI"
                    />
                </CustomFormItem>

                <CustomFormItem name={'ivr_id'}>
                    <Select
                        style={{ width: 200 }}
                        placeholder="IVR"
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => handleFilterOption(input, option, 'label')}
                        filterSort={(optionA, optionB) =>handleFilterSort(optionA, optionB, 'label')}
                        options={ivrListOptionsData}
                    >
                    </Select>
                </CustomFormItem>

                <CustomFormItem
                    name={'dest_str'}
                    rules={[
                        {
                            max: 256,
                            message: 'The maximum is 256 characters'
                        }
                    ]}
                >
                    <Input
                        style={{minWidth: '300px'}}
                        placeholder="Destination/Subdestination/Prefix"
                    />
                </CustomFormItem>


            </Form>
        </>
    );
};