import React, {useEffect, useMemo, useRef, useState} from 'react'
import {PageContent} from "../../components/PageContent";
import {Col, PageHeader, Row, Space, message, Form, Modal} from "antd";
import BlockIvrList from "./IvrList/BlockIvrList";
import BlockIvrSingle from './IvrSingle/BlockIvrSingle';
import BlockIvrListFilter from './IvrList/BlockIvrListFilter';
import {generateId, getIvrActionDefaultParams, getIvrActionNullCastToType} from "../../utils";
import IvrActionParams from "./IvrActionParams/IvrActionParams";
import { api } from '../../api/loginRoutes';
import {IVR_MODIFY_API, IVR_CREATE_API} from "../../const";
import IvrModalRemove from "./IvrModalRemove";


const Ivr = (
    {
        data,
        loading,
        ivrTypes,
        selectedIvrId,
        ivrSoundsList,
        langPacksList,
        getIvrList,
        getIvrTypes,
        getSoundList,
        getLangPacksList,
        selectIvrId,
        ivrTypesRandomSoundFolders,
        ivrTypesStructuredSoundFolders
    }
) => {
    const formRefs = useRef({});
    const [nameForm] = Form.useForm();
    const [filter, setFilter] = useState({});
    const [currentIvrId, setCurrentIvrId] = useState(selectedIvrId || null);
    const [isIvrEditable, setIsIvrEditable] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [ivrActionOrderedList, setIvrActionOrderedList] = useState([]);
    const [currentActionId, setCurrentActionId] = useState(null);
    const [isIvrChanged, setIsIvrChanged] = useState(false);

    const [isModalRemoveVisible, setIsModalRemoveVisible] = useState(false);
    const [isModalDiscardVisible, setIsModalDiscardVisible] = useState(false);
    const [preselectedIvrId, setPreselectedIvrId] = useState(null);

    const [actionValidation, setActionValidation] = useState(null);

    // memo: active IVR data
    const currentIvr = useMemo(() => {
        return currentIvrId
            ? data.find(item => item.id === currentIvrId)
            : null
    }, [currentIvrId, data]);


    const currentActionTypeId = useMemo(() => {
        if (!ivrActionOrderedList?.length || !currentActionId) {
            return null
        }

        const currentActionType = ivrActionOrderedList.find(item => item.id === currentActionId);
        return currentActionType ? currentActionType.action_id : null;
    }, [ivrActionOrderedList, currentActionId]);

    const actionList = useMemo(() => {
        return ivrActionOrderedList.reduce((summ, item, index) => {
            const {...params} = {...item.params};
            const {sound} = {...params};

            if (sound) {
                delete params.sound;
            }


            if (params && params.hangup_codes) {
                params.hangup_codes = Object.keys(params.hangup_codes).map(item => {
                    const code = item.split('sip_')[1];
                    return {
                        sip: +code,
                        percent: +params.hangup_codes[item],
                    }
                });
            }
            const soundList = params?.sound_list && params.sound_list.length ? {sound_id_list: params.sound_list} :
                item?.sound_list && item.sound_list.length ? {sound_id_list: item.sound_list.map(snd => snd?.id)} : {};
            delete params.sound_list;

            return [
                ...summ,
                {
                    position: index + 1,
                    action_id: item.action_id,
                    action_name: item.name,
                    sound_id: sound,
                    params: params,
                    ...soundList,
                }
            ]
        }, [])
    }, [ivrActionOrderedList]);


    // effect: get misc data
    useEffect(() => {
        getIvrTypes();
        getSoundList();
        getLangPacksList();
    }, []);


    // effect: get ivr list on filter change
    useEffect(() => {
        getIvrList(filter);
        selectIvrId(null);
    }, [filter]);


    // effect: set current IVR ID on data fetch
    useEffect(() => {
        if (!data.length) {
            setCurrentIvrId(null);
            return;
        }

        if ( !data.find(item => item.id === currentIvrId) ) {
            setCurrentIvrId(data[0].id);
        }

    }, [data]);


    useEffect(() => {

        formRefs.current = {};
        setIsIvrChanged(false);

        if (nameForm) {
            nameForm.setFieldsValue({name: currentIvr?.name || ""});
        }

        // create New IVR
        if (!currentIvr && isIvrEditable) {
            const currentId = generateId();
            setIvrActionOrderedList([{
                id: currentId,
                action_id: 'WaitBeforeConnect',
                params: getIvrActionDefaultParams('WaitBeforeConnect', ivrTypes),
            }]);
            setCurrentActionId(currentId);
            return;
        }

        // empty action list
        if (!currentIvr || !currentIvr?.action_list?.length) {
            setIvrActionOrderedList([]);
            setCurrentActionId(null);
            return;
        }

        // set ivr action
        const actionList = currentIvr.action_list.map(item => {
            const id = generateId();

            const params = {...(item?.params || {})};
            if (item.sound) {
                params.sound = item.sound.id;
            }

            if (params.hangup_codes) {
                params.hangup_codes = params.hangup_codes.reduce((summ, item) => ({
                    ...summ,
                    ['sip_' + item.sip]: item.percent,
                }), {});
            }
            return {
                id: id,
                name: item?.action_type?.name,
                params: params,
                action_id: item?.action_type?.action_id,
                ...(item?.sound_list && item.sound_list.length ? {sound_list: item?.sound_list} : {})
            }
        });
        setIvrActionOrderedList(actionList);
        setCurrentActionId(actionList[0].id);
        setActionValidation(null);
    }, [currentIvr, isIvrEditable]);


    const handleCreateIvr = (ignoreCheck = false) => {
        if (!ignoreCheck && isIvrChanged && !isModalDiscardVisible) {
            setPreselectedIvrId(null);
            setIsModalDiscardVisible(true);
            return;
        }
        nameForm.resetFields();
        setCurrentIvrId(null);
        setIsIvrEditable(true);
    };


    const handleSelectIvr = (value, ignoreCheck = false) => {
        if (!ignoreCheck && isIvrChanged) {
            setPreselectedIvrId(value);
            setIsModalDiscardVisible(true);
            return;
        }
        setCurrentIvrId(value);
        setIsIvrEditable(false);
    };


    const discardChanges = () => {
        setIsIvrChanged(false);
        setIsModalDiscardVisible(false);

        if (preselectedIvrId) {
            handleSelectIvr(preselectedIvrId, true);
        } else {
            handleCreateIvr(false);
        }
    };


    const changeIvrActionOrderedList = (data) => {
        setIsIvrChanged(true);
        setActionValidation(null);
        setIvrActionOrderedList(data);
    };


    const handleIvrSubmit = async () => {

        // validate name
        try {
            await nameForm.validateFields();
        } catch (errInfo) {
            return;
        }

        // validate params
        for ( const id of Object.keys(formRefs.current) ) {
            const ref = formRefs.current[id];

            if (!ref) {
                continue;
            }

            try {
                await ref.validateFields();
            } catch (errInfo) {
                setCurrentActionId(id);
                return;
            }
        }

        
        const name = nameForm.getFieldValue('name');

        const actionIsNull = actionList.find(action => action.action_id === null);


        if (actionIsNull) {
            setActionValidation({action_id: null});
            message.error('Action is null');
            return;
        }


        // setSubmitIvrDataLoading(true);
        const method = currentIvrId
            ? IVR_MODIFY_API
            : IVR_CREATE_API;

        const methodData = {
            name,
            action_list: actionList,
        };

        if (currentIvrId) {
            methodData.target = {ivr_id: currentIvrId}
        }

        setSubmitLoading(true);
        api(method, methodData)
            .then((r) => {
                if (!r) {
                    return;
                }
                message.success(`IVR "${name}" succesfully updated`);
                setIsIvrEditable(false);
                setCurrentIvrId(r?.ivr?.id);
                getIvrList();
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };


    return (
        <PageContent>

            <PageHeader
                className="site-page-header"
                title="IVRs"
            />

            <Space
                direction="vertical"
                size={20}
                style={{width: '100%'}}
            >

                <BlockIvrListFilter
                    setFilter={setFilter}
                />

                <Row gutter={50}>
                                
                    <Col span={5}>

                        <BlockIvrList
                            data={data}
                            loading={loading}
                            currentIvr={currentIvr}
                            isIvrEditable={isIvrEditable}
                            selectedKeys={currentIvrId ? [currentIvrId] : []}
                            setFilter={setFilter}
                            onClickCreate={handleCreateIvr}
                            onSelectIvr={handleSelectIvr}
                        />

                    </Col>

                                                
                    <Col span={14}>
                        
                        <BlockIvrSingle
                            actionValidation={actionValidation}
                            currentIvr={currentIvr}
                            loading={loading || submitLoading}
                            onIvrSubmit={handleIvrSubmit}
                            onClickEdit={() => setIsIvrEditable(true)}
                            onClickDiscard={() => setIsIvrEditable(false)}
                            form={nameForm}
                            setIvrActionOrderedList={changeIvrActionOrderedList}
                            actionList={actionList}
                            {...{
                                ivrTypes,
                                getIvrList,
                                isIvrEditable,
                                ivrSoundsList,
                                currentActionId,
                                ivrActionOrderedList,
                                setCurrentActionId,
                                setIsIvrChanged,
                                setIsModalRemoveVisible,
                            }}
                        />

                    </Col>

                    <Col span={5}>

                        <IvrActionParams
                            ivrTypes={ivrTypes}
                            currentIvrId={currentIvrId}
                            langPacksList={langPacksList}
                            isIvrEditable={isIvrEditable}
                            forwardedRefs={formRefs}
                            ivrActionOrderedList={ivrActionOrderedList}
                            ivrSoundsList={ivrSoundsList}
                            ivrTypesRandomSoundFolders={ivrTypesRandomSoundFolders}
                            ivrTypesStructuredSoundFolders={ivrTypesStructuredSoundFolders}
                            currentActionId={currentActionId}
                            currentActionTypeId={currentActionTypeId}
                            setIvrActionOrderedList={changeIvrActionOrderedList}
                        />

                    </Col>

                </Row>

            </Space>

            <Modal
                title={`Discard all changes?`}
                visible={isModalDiscardVisible}
                onOk={discardChanges}
                onCancel={() => setIsModalDiscardVisible(false)}
            >
                <p>Are you sure?</p>
            </Modal>

            <IvrModalRemove
                {...{
                    isModalRemoveVisible,
                    currentIvr,
                }}
                onCancel={() => setIsModalRemoveVisible(false)}
                onSuccess={() => {
                    setIsIvrEditable(false);
                    getIvrList(filter);
                }}
            />

        </PageContent>
    );
};

export default Ivr