import {combineReducers} from 'redux';
import auth from './auth';
import roles from './roles';
import ivr from './ivr';
import cli_groups from './cli_groups';
import routing from './routing';
import folders from './folders';
import structured_folders from './structured_folders';
import sounds from './sounds';
import references from './references';
import langpacks from './langpacks';


export default combineReducers({
    ivr,
    auth,
    roles,
    routing,
    folders,
    structured_folders,
    sounds,
    cli_groups,
    references,
    langpacks,
});