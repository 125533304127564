import React, {useMemo} from "react";
import styled from "styled-components";
import {Button, List, Tooltip} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import MinusOutlined from "@ant-design/icons/lib/icons/MinusOutlined";
import CaretUpOutlined from "@ant-design/icons/lib/icons/CaretUpOutlined";
import CaretDownOutlined from "@ant-design/icons/lib/icons/CaretDownOutlined";
import {CustomSelect} from '../../../components/base/Form/CustomSelect';


export default (
    {

        ivrTypes,
        isIvrEditable,
        currentActionId,
        ivrActionOrderedList,

        actionValidation,

        setCurrentActionId,
        onClickAdd,
        onClickRemove,
        onChangeType,
        onClickIncrease,
        onClickDecrease,
    }
) => {


    const ivrTypesSelectData = useMemo(() => {
        return ivrTypes.map(item => ({value: item.action_id, label: item.name})).filter(item => item.value !== "Wait");
    }, [ivrTypes]);

    return (
        <>
            <List>
                {ivrActionOrderedList && ivrActionOrderedList.map((item, index) => {
                    const buttonType = currentActionId === item.id ? 'primary' : 'dashed';
                    const actionNotValid = actionValidation && actionValidation?.action_id === item?.action_id;

                    return (
                        <List.Item
                            key={index}
                            onClick={() => setCurrentActionId(item.id)}
                        >
                            <CustomSelect
                                disabled={!isIvrEditable}
                                options={ivrTypesSelectData}
                                value={item?.action_id}
                                placeholder="Action"
                                onChange={(value) => onChangeType(index, value)}
                                className={actionNotValid ? 'not-valid' : ''}
                                style={{width: '100%', maxWidth: 400}}
                            />

                            <div className="ivr-action-type-control">

                                <Tooltip title="Add new action">
                                    <Button
                                        disabled={!isIvrEditable}
                                        type={buttonType}
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        onClick={() => onClickAdd(index)}
                                    />
                                </Tooltip>

                                <Tooltip title="Remove action">
                                    <Button
                                        disabled={ivrActionOrderedList.length < 2 || !isIvrEditable}
                                        type={buttonType}
                                        shape="circle"
                                        icon={<MinusOutlined />}
                                        onClick={(e) => onClickRemove(index, e)}
                                        style={{marginLeft: 10}}
                                    />
                                </Tooltip>

                                <Tooltip title="Descrease action position">
                                    <Button
                                        disabled={ivrActionOrderedList.length < 2 || !isIvrEditable}
                                        type={buttonType}
                                        shape="circle"
                                        icon={<CaretUpOutlined />}
                                        onClick={(e) => onClickDecrease(index, e)}
                                        style={{marginLeft: 10}}
                                    />
                                </Tooltip>

                                <Tooltip title="Increase action position">
                                    <Button
                                        disabled={!isIvrEditable}
                                        type={buttonType}
                                        shape="circle"
                                        icon={<CaretDownOutlined />}
                                        onClick={(e) => onClickIncrease(index, e)}
                                        style={{marginLeft: 10}}
                                    />
                                </Tooltip>

                            </div>
                        </List.Item>
                    )
                })}
            </List>
        </>
    )

}


const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledHeaderItem = styled.div`
    .rs-btn {
        margin-left: 20px;
    }
`;

const BlockHeader = styled.h5`
    font-family: inherit;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-brand1);
`;