import React, {useEffect, useMemo, useState} from 'react'
import {Form, Modal, Input, Select} from "antd";
import { api } from '../../api/loginRoutes';
import { ROUTING_CREATE_API, ROUTING_MODIFY_API } from '../../const';
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';
import {clearNullData, fixObjectParams, handleFilterOption, handleFilterSort} from '../../utils';
import styled from 'styled-components';


export default (
    {
        ivrList,
        cliGroupsList,
        isModalCreateVisible,
        onCancel,
        onSuccess,
        currentRoutingId,
        destinationList,
        subdestinationList,
        currentRoutingData,
    }
) => {


    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (!currentRoutingData) {
            return;
        }

        form.setFieldsValue({
            cg_key: currentRoutingData?.cg_key,
            de_key: currentRoutingData?.de_key,
            sde_key: currentRoutingData?.sde_key,
            prefix: currentRoutingData?.rt_prefix,
            ivr_id: currentRoutingData?.ivr_id,
            tprefix: currentRoutingData?.rt_tprefix,
        });
    }, [currentRoutingData]);


    const ivrListOptionsData = useMemo(() => {
        return ivrList.map(item => ({value: item.id, label: item.name}));
    }, [ivrList]);

    const cliGroupsListOptionsData = useMemo(() => {
        return cliGroupsList.map(item => ({value: item.cg_key, label: item.name}));
    }, [cliGroupsList]);

    const destinationListOptionsData = useMemo(() => {
        return destinationList.map(item => ({value: item.de_key, label: item.name}));
    }, [destinationList]);

    const subdestinationListOptionsData = useMemo(() => {
        return subdestinationList.map(item => ({value: item.sde_key, label: item.name}));
    }, [subdestinationList]);


    const handleSubmit = (formData) => {
        const params = clearNullData(formData);


        if (currentRoutingId) {
            params['rt_key'] = currentRoutingId;
        }

        const method = !currentRoutingId
            ? ROUTING_CREATE_API
            : ROUTING_MODIFY_API;

        setLoading(true);

        const paramsFixed = fixObjectParams(params, undefined, null);

        api(method, paramsFixed)
            .then(r => {
                onCancel();
                onSuccess();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const leaveOnlyOneParam = (paramKey) => {
        const followingParamKeyList = ['de_key', 'sde_key', 'prefix']
        followingParamKeyList.forEach(key => {
            if (key !== paramKey) {
                form.setFieldsValue({
                    [key]: undefined
                })
            }
        })
    }

    return (
        <Modal
            title={!currentRoutingId ? "Add new routing" : "Edit routing"}
            visible={isModalCreateVisible}
            confirmLoading={loading}
            width={740}
            onOk={() => {
                form.validateFields()
                    .then((formData) => {
                        handleSubmit(formData);
                        // form.resetFields();
                        // onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            onCancel={onCancel}
            afterClose={() => {
                form.resetFields();
            }}
        >

            <StyledForm
                form={form}
                autoComplete="off"
                labelCol={{span: 6}}
                // wrapperCol={{span: 18}}
            >
                <CustomFormItem
                    name={'tprefix'}
                    label={'Tech prefix'}
                    rules={[
                        {
                            required: !currentRoutingId,
                            message: 'Required',
                        },
                        ({getFieldValue}) => ({
                            validator(_, v) {
                                const value = getFieldValue('tprefix');
                                const rule = new RegExp(/^\d*[#]?\d*$/m);

                                if ((value.length < 1 || value.length > 15) || !rule.test(value)) {
                                    return Promise.reject('Type correct tech prefix');
                                }
                                return Promise.resolve();
                            }
                        })

                    ]}
                >
                    <Input
                        disabled={loading}
                        placeholder="Tech prefix"
                    />
                </CustomFormItem>

                <CustomFormItem
                    name={'cg_key'}
                    label={'CLI group'}
                >
                    <Select
                        disabled={loading}
                        placeholder="CLI group"
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => handleFilterOption(input, option, 'label')}
                        filterSort={(optionA, optionB) =>handleFilterSort(optionA, optionB, 'label')}
                        options={cliGroupsListOptionsData}
                    >
                    </Select>
                </CustomFormItem>
                <OneRowItem>
                    <CustomFormItem
                        name={'de_key'}
                        label={'Destination'}
                        style={{width: '49%'}}
                    >
                        <Select
                            disabled={loading}
                            placeholder="Destination"
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input, option) => handleFilterOption(input, option, 'label')}
                            filterSort={(optionA, optionB) =>handleFilterSort(optionA, optionB, 'label')}
                            options={destinationListOptionsData}
                            onChange={() => leaveOnlyOneParam('de_key')}
                        />
                    </CustomFormItem>

                    <CustomFormItem
                        name={'sde_key'}
                        label={'Subdestination'}
                        style={{width: '49%'}}
                    >
                        <Select
                            disabled={loading}
                            placeholder="Subdestination"
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input, option) => handleFilterOption(input, option, 'label')}
                            filterSort={(optionA, optionB) =>handleFilterSort(optionA, optionB, 'label')}
                            options={subdestinationListOptionsData}
                            style={{maxWidth: 220}}
                            onChange={() => leaveOnlyOneParam('sde_key')}
                        >
                        </Select>
                    </CustomFormItem>
                    
                </OneRowItem>

                <CustomFormItem
                    name={'prefix'}
                    label={'Prefix'}
                    rules={[
                        ({}) => ({
                            validator(_, value) {
                                const maxLength = 12;
                                const numberRule = new RegExp(/^\d+$/);

                                if (value && !numberRule.test(value)) {
                                    return Promise.reject('Please enter correct prefix');
                                }
                                if (value && value.length > maxLength) {
                                    return Promise.reject(`Prefix must be less than ${maxLength} characters`);
                                }

                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input
                        disabled={loading}
                        placeholder="Prefix"
                        onChange={() => leaveOnlyOneParam('prefix')}
                    />
                </CustomFormItem>

                <CustomFormItem
                    name={'ivr_id'}
                    label={'Select IVR'}
                >
                    <Select
                        disabled={loading}
                        placeholder="Select IVR"
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => handleFilterOption(input, option, 'label')}
                        filterSort={(optionA, optionB) =>handleFilterSort(optionA, optionB, 'label')}
                        options={ivrListOptionsData}
                    >
                    </Select>
                </CustomFormItem>
            </StyledForm>

        </Modal>
    );
};

const OneRowItem = styled.div`
    && {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        width: 100%;
        justify-content: space-between;

        .ant-form-item-label {
            min-width: 110px;
            text-align: left;
        }

        .ant-form-item-control {
            min-width: 180px;
            width: 100%;
        }
    }
`;

const StyledForm = styled(Form)`
    && .ant-row.ant-form-item > .ant-col.ant-form-item-label {
        text-align: left;

        max-width: 110px;
    }
    
`;