import React, {useEffect, useState} from 'react'
import {PageContent} from "../../components/PageContent";
import {PageHeader, Space} from "antd";
import CliGroupsTable from "./CliGroupsTable";
import {CLI_GROUP_FETCH_API} from "../../const";
import {getCsvResponse} from "../../api/loginRoutes";
import CliGroupsCreateForm from "./CliGroupsCreateForm";
import CliGroupsFilter from "./CliGroupsFilter";
import CliGroupsModalRemove from "./CliGroupsModalRemove";
import {downloadDataAsFile} from '../../utils/downloadHelpers';


export default (
    {
        data,
        loading,
        selectedCliGroupId,
        getCliGroupsList,
        selectCliGroupId
    }
) => {
    const [filter, setFilter] = useState({str: selectedCliGroupId || ''});
    const [currentCliGroupId, setCurrentCliGroupId] = useState({});
    const [isModalRemoveVisible, setIsModalRemoveVisible] = useState(false);
    const [groupsDownloadingList, setGroupsDownloadingList] = useState([]);


    useEffect(() => {
        getCliGroupsList(filter);
        selectCliGroupId(null);
    }, [filter]);


    const handleDownload = (id) => {
        setGroupsDownloadingList([...groupsDownloadingList, id]);
        getCsvResponse(CLI_GROUP_FETCH_API, {cg_key: id})
            .then(r => {
                if (!r) {
                    return;
                }

                const data = r.toString();
                downloadDataAsFile(data);
            })
            .finally(() => {
                setGroupsDownloadingList(groupsDownloadingList.filter(item => item.cg_key === id));
            });
    }


    return (
        <PageContent>

            <PageHeader
                className="site-page-header"
                title="CLI Groups"
            />

            <Space
                direction="vertical"
                size={20}
                style={{width: '100%'}}
            >

                <CliGroupsFilter
                    filter={filter}
                    setFilter={setFilter}
                />

                <CliGroupsTable
                    data={data}
                    loading={loading}
                    downloadingList={groupsDownloadingList}
                    updateData={() => getCliGroupsList(filter)}
                    onClickDownload={handleDownload}
                    onClickRemove={(id) => {
                        setCurrentCliGroupId(id);
                        setIsModalRemoveVisible(true);
                    }}
                />

                <CliGroupsCreateForm
                    getCliGroupsList={() => getCliGroupsList(filter)}
                />

            </Space>

            <CliGroupsModalRemove
                {...{
                    currentCliGroupId,
                    isModalRemoveVisible,
                }}
                onSuccess={() => getCliGroupsList(filter)}
                onCancel={() => {
                    setCurrentCliGroupId(null);
                    setIsModalRemoveVisible(false);
                }}
            />

        </PageContent>
    );
};