import {connect} from 'react-redux';
import CliGroups from "./CliGroups";
import {getCliGroupsList, selectCliGroupId} from "../../actions/cli_groups";


const mapState = ({cli_groups}) => ({
    data: cli_groups.list,
    loading: cli_groups.loading,
    selectedCliGroupId: cli_groups.selectedId
});

export default connect(mapState, {
    getCliGroupsList,
    selectCliGroupId
})(CliGroups);