import {
    SET_ROLES
} from '../const/';

const initialState = {
    role_list: [],
};


const handlers = {    
    [SET_ROLES]: (state, {payload}) => ({
        ...state,
        role_list: payload,
    }),

    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};
