import React, {Component} from 'react';
import {Button, Form, Input, Space, message, Typography} from 'antd';
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';
import {withRouter} from 'react-router-dom';
import history from "../../config/history";
import styled from 'styled-components';
import {notAuthApi} from "../../api/auth";
import {DEFAULT_ERROR_MESSAGE, DEFAULT_NOT_AUTH_PATH} from '../../const';
import Recaptcha from 'react-google-invisible-recaptcha';


const emailRule = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const passwordRule = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])[A-Za-z'\d#?!_@$%^&*-]{8,}$/);
const {Title} = Typography;


class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.formRef = null;
        this.recaptchaRef = null;

        this.state = {
            isTokenValid: false,
            formValues: {},
        };
    }

    componentDidMount() {
        const token = this.props.match.params.token;
        document.body.classList.remove("rtl");

        if (token) {
            this.checkToken(token);
        }
    }

    componentWillUnmount() {
        this.resetRecaptcha();
    }

    checkToken = (token) => {
        notAuthApi('user__reset_password:check', {token}).then(() => {
            this.setState({isTokenValid: true});
        }).catch(() => {
            message.error('Token expired, please try to reset password again');
        });
    };

    handleSubmitResetRequest = () => {
        this.executeRecaptcha();
    };


    handleResolveCaptcha = (token) => {
        if (!token) {
            message.error("Bot verification failed");
            this.resetRecaptcha();
            return;
        }

        notAuthApi('user__reset_password:request', {
            ...this.formRef?.getFieldsValue("email"),
            site: 1,
            captcha_token: token
        })
            .then(response => {
                if (response !== undefined) {
                    message.success('Email was successfully sent! Check your SPAM folder');
                    history.push(DEFAULT_NOT_AUTH_PATH);
                }
            })
            .catch(() => {
                message.error('Incorrect email');
            })
            .finally(() => {
                this.resetRecaptcha();
            });
    };


    handleSubmitNewPassword = () => {
        const {formValue} = this.state;

        const password = formValue.password;
        const token = this.props.match.params.token;

        notAuthApi('user__reset_password:modify', {password, token}).then((response) => {
            message.success('Password successfully changed');
            history.push(DEFAULT_NOT_AUTH_PATH);
        }).catch(() => {
            message.error(DEFAULT_ERROR_MESSAGE);
        });

    };


    executeRecaptcha = () => {
        if (this.recaptchaRef?.execute) {
            this.recaptchaRef.execute();
        }
    };

    resetRecaptcha = () => {
        if (this.recaptchaRef?.reset) {
            this.recaptchaRef.reset();
        }
    };


    render() {
        const isTokenValid = this.state.isTokenValid;

        return (
            <StyledContainer>
                <Space direction="vertical" size={16} style={{width: '100%'}}>
                    <Title level={3}>Reset Password</Title>
                    <Form
                        ref={ref => this.formRef = ref}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                        onFinish={ (value) => {
                            this.setState({formValue: value});

                            if (!isTokenValid) {
                                this.handleSubmitResetRequest()
                            } else if (isTokenValid) {
                                this.handleSubmitNewPassword(value)
                            }
                        }}
                    >
                        {!isTokenValid && <>
                            <CustomFormItem
                                label="Please, enter an email address"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!'
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!emailRule.test(getFieldValue('email'))) {
                                                return Promise.reject('Please enter an email address');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </CustomFormItem>
                            <Button type="primary" htmlType="submit">
                                Reset
                            </Button>
                        </>}

                        {isTokenValid && <>
                            <CustomFormItem
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!'
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!passwordRule.test(getFieldValue('password'))) {
                                                return Promise.reject('Must contain at least one number and one uppercase and lowercase letter');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input type="password" />
                            </CustomFormItem>
                            <CustomFormItem
                                label="Confirm password"
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!'
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (getFieldValue('password') !== getFieldValue('confirm_password')) {
                                                return Promise.reject('Passwords must be same');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input type="password"/>
                            </CustomFormItem>
                            <Button type="primary" htmlType="submit">
                                Reset Password
                            </Button>
                        </>}
                    </Form>

                    <Recaptcha
                        ref={ref => this.recaptchaRef = ref}
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onResolved={this.handleResolveCaptcha}
                    />

                </Space>
            </StyledContainer>
        );
    }
}

export default withRouter((ResetPassword));

const StyledContainer = styled.div`
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    min-width: 340px;
    width: 100%;
`;