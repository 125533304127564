import {connect} from 'react-redux';
import Folders from "./Folders";
import {getFoldersList, selectFolderId, /* getFoldersDropdownList, getIvrFoldersList  */} from '../../actions/folders';
import {selectIvrId} from "../../actions/ivr";

const mapState = ({folders}) => ({
    data: folders.list,
    loading: folders.loading,
    selectedFolderId: folders.selectedId,
 /* ivrList: folders.ivr_list, */
});

export default connect(mapState, {
 /* getIvrList: getIvrFoldersList, */
    selectIvrId,
    getFoldersList,
    selectFolderId,
 /* getFoldersDropdownList, */
})(Folders);