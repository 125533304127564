import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, PageHeader, Space} from 'antd';
import SoundsModalCreate from './SoundsModalCreate';
import SoundsFilter from './SoundsFilter';
import SoundsList from './SoundsList';
import {getFileResponse} from '../../api/loginRoutes';
import {downloadDataAsFile} from '../../utils';
import SoundsModalRemove from './SoundsModalRemove';


export default (
    {
        data,
        getSoundList,
    }
) => {


    const [filter, setFilter] = useState({});
    const [currentSoundId, setCurrentSoundId] = useState(null);
    const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
    const [isModalRemoveVisible, setIsModalRemoveVisible] = useState(false);
    const [loadingItems, setLoadingItems] = useState({});

    useEffect(() => {
        getSoundList();
    }, []);

    useEffect(() => {
        getSoundList(filter)
    }, [filter]);
    // const soundsData = useMemo(() => {
    //     return data.filter(sound => {
    //         return !filter.str || sound.filename.includes(filter.str)
    //     })
    // }, [data, filter]);

    const addLoadingItems = useCallback((id) => {
        setLoadingItems(prev => ({...prev, [id]: true}));
    }, [loadingItems]);


    const removeLoadingItems = useCallback((id) => {
        setLoadingItems(prev => ({...prev, [id]: false}));
    }, [loadingItems]);


    const handleClickReplace = (id) => {
        setIsModalCreateVisible(true);
        setCurrentSoundId(id);
    };

    const handleClickDownload = (id, filename) => {
        addLoadingItems(id);
        getFileResponse('ivr_sound:download', {target: {ivr_sound_id: id}}, 'file')
            .then(r => {
                if (!r) {
                    return;
                }
                downloadDataAsFile(r, filename);
            })
            .finally(() => {
                removeLoadingItems(id);
            });
    };

    const handleClickRemove = (id) => {
        setIsModalRemoveVisible(true);
        setCurrentSoundId(id);
    };

    const currentSoundData = useMemo(() => {
        if (!currentSoundId || !data) {
            return null;
        }

        return data.find(item => item.id === currentSoundId);
    }, [data, currentSoundId]);

    return (
        <>

            <Space
                direction="vertical"
                size={20}
                style={{width: '100%'}}
            >

                <div style={{display: 'flex'}}>
                    
                    <SoundsFilter
                        setFilter={setFilter}
                    />

                    <Button
                        style={{marginLeft: 'auto'}}
                        onClick={() => {
                            setCurrentSoundId(null);
                            setIsModalCreateVisible(true);
                        }}
                    >
                        Add new
                    </Button>

                </div>

                <SoundsList
                    data={data}
                    loadingItems={loadingItems}
                    onClickReplace={handleClickReplace}
                    onClickDownload={handleClickDownload}
                    onClickRemove={handleClickRemove}
                />

            </Space>

            <SoundsModalCreate
                {...{
                    currentSoundId,
                    currentSoundData,
                    isModalCreateVisible,
                }}
                onSuccess={() => getSoundList(filter)}
                onCancel={() => {
                    setCurrentSoundId(null);
                    setIsModalCreateVisible(false);
                }}
            />

            <SoundsModalRemove
                {...{
                    currentSoundId,
                    currentSoundData,
                    isModalRemoveVisible,
                }}
                onSuccess={() => getSoundList(filter)}
                onCancel={() => {
                    setCurrentSoundId(null);
                    setIsModalRemoveVisible(false);
                }}
            />

        </>
    );
};