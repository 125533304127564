import React, {useState} from 'react'
import {Modal} from "antd";
import { api } from '../../api/loginRoutes';


export default (
    {
        isModalRemoveVisible,
        currentPackKey,
        onCancel,
        onSuccess,
    }
) => {

    const [loading, setLoading] = useState(false);

    const handleRemove = () => {
        if (!currentPackKey) {
            return;
        }

        setLoading(true);
        api('langpack:remove', {lp_key: currentPackKey})
            .then(r => {
                onCancel();
                onSuccess(currentPackKey);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <Modal
            title={`Delete language pack`}
            visible={isModalRemoveVisible}
            confirmLoading={loading}
            onOk={handleRemove}
            onCancel={onCancel}
        >
            <p>Are you sure?</p>
        </Modal>
    );
};