import {connect} from 'react-redux';
import Sounds from "./Sounds";
import {getSoundList} from '../../actions/sounds';


const mapState = ({sounds}) => ({
    data: sounds.list,
    loading: sounds.loading,
});

export default connect(mapState, {
    getSoundList,
})(Sounds);