import {
    ROUTING as PREFIX, SET_GROUP_ROUTING,
} from '../const';

const initialState = {
    list: [],
    ivr_list: [],
    tprefix_list: [],
    cligroup_list: [],
    loading: true,
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {list}}) => ({...state, list: list}),
    [SET_GROUP_ROUTING]: (state, {payload: {ivr_list, tprefix_list, cligroup_list}}) => ({
        ...state, ivr_list: ivr_list, tprefix_list: tprefix_list, cligroup_list: cligroup_list
    }),
    ['SET_IVR_LIST' + PREFIX]: (state, {payload: {ivr_list}}) => ({...state, ivr_list}),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}