/* Routes */
export const DEFAULT_AUTH_PATH = '/ivrs';
export const DEFAULT_NOT_AUTH_PATH = '/login';


/* Defaults */
export const DEFAULT_ALERT_DISPLAY_DURATION = 5000;


/* Default Texts */
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

/* MISC */
export const EOL = navigator.appVersion.indexOf("Win") !== -1 ? '\n' : '\r\n';