import React, {useRef, useState} from 'react'
import {connect} from 'react-redux';
import {loginUser} from '../../actions/auth';
import {Form, Input, Button, Typography, Space} from "antd";
import styled from 'styled-components';
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';


const {Title} = Typography;

const Login = (
    {
        loginUser,
    }
) => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = (formValue) => {
        setLoading(true);
        loginUser(formValue)
            .then(r => {
                console.log(r);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <StyledContainer>
            <Space direction="vertical" size={16} style={{width: '100%'}}>
                <Title level={3}>Login</Title>

                <Form
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    onFinish={handleSubmit}
                >
                    <CustomFormItem
                        label="Email address or login"
                        name="login"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </CustomFormItem>

                    <CustomFormItem
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </CustomFormItem>

                    <CustomFormItem>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Sign in
                        </Button>
                        <Link className="reset-password" to="/reset-password">
                            Forgot password?
                        </Link>
                    </CustomFormItem>
                </Form>
            </Space>
        </StyledContainer>
    );
};


const mapState = ({auth}) => ({
    loading: auth.loading,
    secondAuthType: auth.secondAuthType,
    secondAuthLink: auth.secondAuthLink,
    secondAuthEmail: auth.secondAuthEmail,
    lang: auth.lang,
});


export default connect(mapState, {
    loginUser,
})(withRouter(Login));


const StyledContainer = styled.div`
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    min-width: 340px;
    width: 100%;
`;