import {EOL} from '../const';

import _each from 'lodash/each';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import {api} from '../api/loginRoutes';

// Checks if value is an empty object, collection, map, or set.
export const isEmpty = (value) => _isEmpty(value);

// Checks if value is the language type of Object. (e.g. arrays, functions, objects, regexes, new Number(0), and new String(''))
export const isObject = (value) => _isObject(value);

export const each = _each;

// export const responseErrorToString = (error, code) => {
//
//     if (code === 409 && error?.data?.error_type) {
//         return `Error: ${error.data.error_type}`
//     }
//
//     if ( !error?.data || !Object.keys(error.data).length ) {
//         return error.message;
//     }
//
//     const errorString = Object
//         .keys(error.data)
//         .reduce((summ, item) => {
//             return 'test error';
//             // const {errMessage, fieldKey} = item;
//             // return summ += `${fieldKey}: ${errMessage}${EOL}`;
//         }, '');
//
//     return errorString;
// };

export const fixObjectParams = (params, toReplace, replaceTo) => {
    return params ? Object.keys(params).reduce((acc, key) => {
        if (params[key] !== toReplace) {
            acc[key] = params[key];
        } else {
            acc[key] = replaceTo;
        }
        return acc;
    }, {}) : {}
};


export const toTimestamp = (strDate) => {
    const dt = new Date(strDate).getTime();
    return dt / 1000;
};


export const responseErrorToString = (error, code) => {

    if (code === 409 && error?.data?.error_type) {
        return `Error: ${error.data.error_type}`
    }

    let errorString = '';

    if (isObject(error.data) && !isEmpty(error.data)) {
        each(error.data, (errMessage, fieldKey) => {
            errorString += `${fieldKey}: ${errMessage}${EOL}`;
        });
    } else {
        if (error.data && !!error.data.length) {
            errorString = error.data;
        } else {
            errorString = error.message;
        }
    }

    return errorString;
};

export const isFile = (input) => {
    return 'File' in window && input instanceof File;
};

export const isBlob = (input) => {
    return 'Blob' in window && input instanceof Blob;
};

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    const fileOrigin = file?.originFileObj;

    if (fileOrigin && isFile(fileOrigin) && isBlob(fileOrigin)) {

        reader.readAsDataURL(fileOrigin);
    }

    reader.onload = () => {
        resolve(reader.result);
    };
    reader.onerror = error => reject(error);


});

export const createFileFromUrl = async (file) => {
    const fileObject = file;
    let response = await fetch(file?.url_wav);
    let data = await response.blob();
    let metadata = {
        type: response.headers.get('content-type') || 'audio/x-wav'
    };
    return new File([data], fileObject.name, metadata);
};

export const generateId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
}


export const getIvrActionDefaultParams = (actionName, ivrTypes = []) => {
    if (!actionName)
        return {};

    const type = ivrTypes.find(item => actionName === item.action_id);

    if (!type) {
        return {}
    }

    if ('RejectCall' === actionName) {
        return {
            percent: type.params_default.percent,
            hangup_codes: type.params_default.hangup_codes.reduce((summ, item) => ({
                ...summ,
                ['sip_' + item.sip]: item.percent,
            }), {}),

        };
    }

    return Object.keys(type.params_validation)
        .reduce((sum, item) => {
            const minimumValue = type.params_validation[item].minimum;
            return {
                ...sum,
                [item]: (minimumValue || minimumValue === 0) ? minimumValue : null};
        }, {});
};


export const getIvrActionNullCastToType = (actionList, ivrTypes = []) => {
    if (!ivrTypes.length)
        return {};

    return actionList.reduce((result, action) => {

        const type = ivrTypes.find(item => action.action_id === item.name);

        if (!type) {
            result.push(action);
            return result;
        }
        
        const params = action.params;

        const changedParams = Object.keys(type.params_validation).reduce((resultParams, key) => {
            resultParams[key] = key in params ? params[key] : null;

            if (type.params_validation[key].type === "string") {
                if (!(key in params) || key in params && params[key] === null) {
                    resultParams[key] = "";
                }
            }

            return resultParams;
        }, {});
        
        action["params"] = changedParams;
        
        result.push(action);

        return result;

    }, [])
    
   
};


export const clearNullData = (data) => {
    let resultData = null;

    if (data !== null && Array.isArray(data)) {
        const currentArray = [...data];
        resultData = currentArray.filter(value => value !== null);
    }

    if (data !== null && !Array.isArray(data) && typeof data === 'object') {
        const currentObject = {...data};
        resultData = Object.keys(currentObject).reduce((value, key) => {
            if (currentObject[key] !== null) {
                value[key] = currentObject[key]
            }

            return value;
        }, {});
    }

    return resultData;
};

export const handleFilterOption = (input, option, nameKey) => {
    return option.hasOwnProperty(nameKey) &&
        option[nameKey] &&
        option[nameKey].toLowerCase().indexOf(input.toLowerCase()) >= 0
};

export const handleFilterSort = (optionA, optionB, nameKey) => {
    return optionA.hasOwnProperty(nameKey) &&
        optionB.hasOwnProperty(nameKey) &&
        optionA[nameKey] &&
        optionB[nameKey] &&
        optionA[nameKey].toLowerCase().localeCompare(optionB[nameKey].toLowerCase())
};

export const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
};

