const createDownloadUrl = (data, type = '') => {
    return window.URL.createObjectURL(new Blob([data], {type: type}));
};

const downloadFileByUrl = (url, name) => {
    if (!url) {
        return;
    }

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();

    setTimeout(function () {
        URL.revokeObjectURL(url);
    }, 0);
};


export const downloadDataAsFile = (data, name = 'file.txt', type = '') => {
    if (data === undefined) {
        return;
    }

    downloadFileByUrl( createDownloadUrl(data, type), name );
};