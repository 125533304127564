import React, {useState} from 'react'
import {Button, Form, Input} from "antd";
import {api} from "../../api/loginRoutes";
import {CLI_GROUP_CREATE_API} from "../../const";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';


export default (
    {
        getCliGroupsList,
        ...props
    }
) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleCreateGroup = (value) => {
        setLoading(true);

        api(CLI_GROUP_CREATE_API, value)
            .then(r => {
                if (!r?.cg_key) {
                    return;
                }
                getCliGroupsList();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>

            <Form
                formItemLayout={'inline'}
                layout={'inline'}
                form={form}
                initialValues={{name: "", prefix_list: ""}}
                onFinish={handleCreateGroup}
            >
                <CustomFormItem
                    name={'name'}
                    style={{flexGrow: 1, paddingLeft: 16}}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <Input
                        disabled={loading}
                        placeholder="Group name"
                    />
                </CustomFormItem>

                <CustomFormItem
                    name={'prefix_list'}
                    style={{flexGrow: 1, paddingLeft: 16}}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, v) {
                                const value = getFieldValue('prefix_list');
                                const numberRule = new RegExp(/^\d+([,;\t\n\s]\s*\d+)*$/m);

                                if (value && value.length && !numberRule.test(value)) {
                                    return Promise.reject('Please enter correct CLI List');
                                }

                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input
                        disabled={loading}
                        placeholder="CLI list"
                    />
                </CustomFormItem>

                <CustomFormItem
                    style={{width: 130, textAlign: 'center'}}
                >

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        icon={<PlusOutlined />}
                    />
                </CustomFormItem>
            </Form>

        </>
    );
};