import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Empty, Form, Input, List, message, Space, Tag, Upload} from 'antd';
import {toBase64} from './../../utils';
import CustomAudioPlayer from '../../components/CustomAudioPlayer/CustomAudioPlayer';
import styled, {css} from 'styled-components';
import SaveOutlined from '@ant-design/icons/lib/icons/SaveOutlined';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';
import {clearNullData, getUniqueListBy, isFile, toTimestamp} from '../../utils';
import {LANGPACK_CREATE_API, LANGPACK_MODIFY_API} from '../../const';
import Spin from 'antd/es/spin';
import LangPacksModalRemove from './LangPacksModalRemove';
import {apiFormData} from '../../api/loginRoutes';

const arrayDefault = new Array(10).fill(null);
const soundPreviewsListDefault = [];


export default (
    {
        currentPackData,
        isNewCurrentPack,
        setCurrentPackId,
        setChangedSoundList,
        onSuccess
    }
) => {

    const [form] = Form.useForm();
    const audioRefs = useRef(new Map());
    const playedSoundId = useRef(null);


    const [soundFiles, setSoundFiles] = useState(arrayDefault);
    const [soundPreviewsList, setSoundPreviewsList] = useState(soundPreviewsListDefault);

    const [loading, setLoading] = useState(false);

    const [isModalRemoveVisible, setModalRemoveVisible] = useState(false);

    // reset previews
    useEffect(() => {
        setSoundPreviewsList(soundPreviewsListDefault);
        clearSounds()
    }, [currentPackData, isNewCurrentPack]);


    // set form values
    useEffect(() => {
        const formData = currentPackData?.langpack_name
            ? {langpack_name: currentPackData?.langpack_name}
            : {langpack_name: ""};

        form.setFieldsValue(formData);
    }, [currentPackData]);


    const clearSounds = () => {
        setSoundFiles(arrayDefault);
    };

    const handleReplaceSound = async (file, digit) => {
        const fileValue = file.fileList.slice(-1);

        const soundFilesUpdated = soundFiles.map((item, index) => (
            digit !== index
                ? item
                : fileValue
        ));
        setSoundFiles(soundFilesUpdated);

        if (!file?.fileList?.[0]?.originFileObj) {
            setSoundPreviewsList(soundPreviewsList.filter(item => +item.digit !== +digit));
            return;
        }

        if (fileValue && fileValue.length) {
            const fileBase64 = await toBase64(fileValue[0]);
            const onlyOneFileByDigit = getUniqueListBy([...soundPreviewsList, {file: fileBase64, digit: digit}], "digit");

            setSoundPreviewsList(onlyOneFileByDigit);
        }
    };


    const handleReplaceSoundUndo = (digit) => {
        setSoundFiles(soundFiles.map((item, index) => index === digit ? null : item));
        setSoundPreviewsList(soundPreviewsList.filter(item => +item.digit !== +digit));
    };


    const handleSubmit = (value) => {
        const params = clearNullData(value);
        const soundFilesClone = [...soundFiles].reduce((acc, val, idx) => {
            if (!val) {
                acc[idx] = currentDigitList.find(item => item && item.length && item[0]?.digit === idx);
            } else {
                acc[idx] = val;
            }
            return acc;
        }, []);

        const nullSoundIndexes = soundFilesClone.reduce((data, sound, idx) => { //currentDigitList
            if (!sound) {
                data.push(idx);
            }
            return data
        }, []);

        if (nullSoundIndexes.length) {
            message.error(`You must upload sounds for the next digits: ${nullSoundIndexes.join(', ')}`);
            return
        }

        // eslint-disable-next-line array-callback-return
        const transformedSoundFiles = soundFiles.reduce((data, sound, idx) => {
            if (sound) {
                const name_splited = data[idx][0].name.split('.');
                const name_ext = name_splited[name_splited.length-1];
                data[idx][0].originFileObj.filename = `${idx}.${name_ext}`
            }
            return data
        }, soundFiles);

        const currentLangPackId = currentPackData && currentPackData?.lp_key;

        if (currentLangPackId) {
            params['lp_key'] = currentLangPackId;
        }

        const dt = new DataTransfer();

        transformedSoundFiles.forEach(async (sound, idx) => {
            if (sound) {
                const soundObject = {...sound[0], ...sound[0].originFileObj};

                let file;
                if (isFile(soundObject?.originFileObj)) {
                    file = soundObject.originFileObj;

                    dt.items.add(file);
                }
            }
        });
        params.upload_file_list = dt.files;

        const method = isNewCurrentPack ? LANGPACK_CREATE_API : LANGPACK_MODIFY_API;

        setLoading(true);

        apiFormData(method, params)
            .then(r => {
                onSuccess().then(() => {
                    setCurrentPackId(r?.lp_key)
                });
            })
            .finally(() => {
                setLoading(false);
            });

    };

    const currentDigitList = useMemo(() => {
        if (currentPackData?.digit_list && currentPackData.digit_list.length) {
            const soundFilesCopy = [...soundFiles];
            const nullFiles = [...arrayDefault];
            const unitedFiles = soundFilesCopy.reduce((acc, value, index) => {
                const item = currentPackData.digit_list.find((v) => +v?.digit === index);

                if (item && !value) {
                    acc[index] = [item];
                } else {
                    acc[index] = value;
                }
                return acc;
            }, nullFiles);

            const currentFiles = unitedFiles
                .reduce((acc, value, index) => {

                    if (value && value.length) {
                        const item = value && value.length && value[0];

                        if (item?.digit) {
                            acc[index] = [{
                                uid: item.digit,
                                lastModified: toTimestamp(item.updated_at),
                                name: item.real_filename_converted,
                                size: null,
                                type: null,
                                percent: 0,
                                url_wav: item.url_wav,
                                originFileObj: {},
                                digit: +item.digit
                            }]
                        } else {
                            acc[index] = [{
                                digit: index,
                                ...item
                            }]
                        }
                    }
                    return acc;
                }, []);

            const notNullFiles = currentFiles.filter(item => !!item);

            const sortedFiles = arrayDefault.reduce((acc, item, idx) => {
                const file = notNullFiles.find((elem => elem && elem.length && elem[0]?.digit === idx));

                if (file) {
                    acc[idx] = file;
                } else {
                    acc[idx] = item;
                }

                return acc;
            }, []);

            return sortedFiles;
        }
        return []
    }, [currentPackData, soundPreviewsList]);
    
    useEffect(() => {

        if (soundPreviewsList.length) {
            setChangedSoundList(true);
        } else {
            setChangedSoundList(false);
        }

    }, [form, soundPreviewsList]);

    return (
        <Form
            form={form}
            layout={'vertical'}
            onFinish={handleSubmit}
        >

            <Space
                size={20}
                direction="vertical"
                style={{width: '100%'}}
            >


                {currentPackData || isNewCurrentPack
                    ? (
                        <CustomFormItem
                            name={'langpack_name'}
                            style={{marginBottom: 0, maxWidth: 300}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input language pack name',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Language pack name"
                                onChange={(event) => {
                                    const { value } = event.target;

                                    if (value !== currentPackData?.langpack_name) {
                                        setChangedSoundList(true);
                                    } else {
                                        setChangedSoundList(false);
                                    }
                                }}
                            />
                        </CustomFormItem>
                    )
                    : (
                        <Input
                            style={{marginBottom: 0, maxWidth: 300}}
                            disabled={true}
                            placeholder="Please, select language pack"
                            onChange={(event) => {
                                const { value } = event.target;

                                if (value !== currentPackData?.langpack_name) {
                                    setChangedSoundList(true);
                                } else {
                                    setChangedSoundList(false);
                                }
                            }}
                        />
                    )
                }


                <StyledListWrapper>
                    {!currentPackData && !isNewCurrentPack

                        ? (
                            <Empty 
                                style={{
                                    alignSelf: 'center',
                                    margin: '0 auto',
                                }}
                            />
                        )
                        
                        : (<Spin spinning={loading} tip={'Loading...'}>
                            <List>
                                {arrayDefault.map((_, index) => {
                                    const soundReplaced = soundPreviewsList.find(item => +item.digit === +index);
                                    const currentSoundData = soundReplaced
                                        ? soundReplaced
                                        : currentPackData?.digit_list
                                            ? currentPackData.digit_list.find(item => item && +item.digit === +index)
                                            : null;
                                    const currentPreview = soundFiles[index] && soundFiles[index][0];
                                    const previewValue = currentPreview ? [{...currentPreview, digit: +index}] : currentDigitList[index];

                                    return (
                                        <StyledListItem hasReplaced={!!soundReplaced}>
                                            
                                            <StyledTag color="default">{index}</StyledTag>

                                            {currentSoundData
                                                ? (
                                                    <CustomAudioPlayer
                                                        ref={(ref) => audioRefs.current.set(currentSoundData.digit, ref)}
                                                        src={!soundReplaced
                                                            ? `${currentSoundData.url_source}?${currentSoundData.filename}`
                                                            : soundReplaced.file
                                                        }
                                                        onPlay={() => {
                                                            if (playedSoundId.current && playedSoundId.current === currentSoundData.digit) {
                                                                return;
                                                            }
                                    
                                                            if (playedSoundId.current) {
                                                                const currentAudioRef = audioRefs.current.get(playedSoundId.current);
                                                                if (currentAudioRef) {
                                                                    currentAudioRef.audio.current.pause();
                                                                }
                                                            }
                                                            playedSoundId.current = currentSoundData.digit;
                                                        }}
                                                    />
                                                )
                                                : null
                                            }

                                            <div class="sound-actions">
                                                <CustomFormItem
                                                    noStyle
                                                >
                                                    {/*
                                                    -- filename absolute
                                                    --
                                                    */}
                                                    {soundReplaced &&
                                                    <RestartButton
                                                        danger
                                                        type="link"
                                                        icon={<DeleteOutlined />}
                                                        style={{marginRight: 10}}
                                                        onClick={() => handleReplaceSoundUndo(index)}
                                                    />
                                                    }
                                                    <StyledUpload
                                                        rules={[
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {

                                                                    if (value && value?.fileList?.length) {
                                                                        return Promise.resolve();
                                                                    }

                                                                    return Promise.reject(new Error('Please upload sound file'));
                                                                },
                                                            }),
                                                        ]}
                                                        accept={'audio/mpeg,audio/wave,audio/wav,audio/x-wav,audio/x-pn-wav'}
                                                        fileList={previewValue}
                                                        onChange={(val) => handleReplaceSound(val, index)}
                                                        beforeUpload={() => false}
                                                        name={`sound-file-${index}`}
                                                    >
                                                        <Button icon={<UploadOutlined />}>Replace by sound</Button>
                                                    </StyledUpload>
                                                </CustomFormItem>
                                            </div>

                                        </StyledListItem>
                                    )
                                })}
                            </List>

                        </Spin>)
                    }
                </StyledListWrapper>


                {currentPackData || isNewCurrentPack
                    ? (
                        <CustomFormItem
                            style={{textAlign: 'right'}}
                        >

                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />}
                            >
                                Save
                            </Button>

                            <Button
                                danger
                                size="large"
                                type="primary"
                                icon={<DeleteOutlined />}
                                style={{marginLeft: 10}}
                                disabled={isNewCurrentPack || currentPackData?.cannot_be_deleted}
                                onClick={() => {
                                    setModalRemoveVisible(true)
                                }}
                            >
                                Delete
                            </Button>
                        </CustomFormItem>
                    )
                    : null
                }

                {(currentPackData || isNewCurrentPack) && isModalRemoveVisible && <LangPacksModalRemove
                    isModalRemoveVisible={isModalRemoveVisible}
                    currentPackKey={currentPackData?.lp_key}
                    onCancel={() => setModalRemoveVisible(false)}
                    onSuccess={onSuccess}
                />}

            </Space>
        </Form>
    )
}


const RestartButton = styled(Button)`
    position: absolute;
    right: 180px;
`;


const StyledTag = styled(Tag)`
    height: 39px;
    width: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 1;
    margin-right: 50px;
`;


const StyledListItem = styled(List.Item)`
    justify-content: stretch;

    .sound-actions {
        display: flex;
        margin-left: auto;
    }

    ${props => props.hasReplaced && css`
        .rhap_container {
            background-color: rgba(243, 243, 243, 0.5);
        }
    `}
`;


const StyledListWrapper = styled.div`
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    min-height: 402px;
    justify-content: center;
    padding: 10px 20px;
    width: 100%;

    .ant-list {
        flex-grow: 1;
    }
    .ant-spin.ant-spin-spinning.ant-spin-show-text {
        max-height: 100%;
    }
`;


const StyledUpload = styled(Upload)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 300px;
    
    .ant-btn.ant-btn-icon-only {
        opacity: 1;
    }
    .ant-upload-list-item-card-actions {
        display: none
    }
    // .ant-upload-list {
    //     display: none;
    // }
`;