import React from 'react'
import {Button, Space, Table} from "antd";
import {Link} from "react-router-dom";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";


export default (
    {
        data,
        loading,
        onSelectRecordRow,
        selectIvrId,
        handleClickCreate,
        handleClickRemove,
        handleClickDownload
    }
) => {

    const columns = [
        {
            title: 'Folder',
            dataIndex: 'dir_name',
            width: '50%',
            editable: true,
        },
        {
            title: 'Files',
            width: '20%',
            editable: true,
            render: (_, record) => {
                return (
                <Space style={{justifyContent: 'space-between', width: '100%'}}>
                    <div>{record.dir_files}</div>
                    <Button
                        style={{marginLeft: 8}}
                        loading={loading}
                        disabled={loading}
                        buttonStyle="default"
                        onClick={() => handleClickDownload(record.dir_key, record.dir_name)}
                        icon={<DownloadOutlined />}
                    />
                </Space>
                )
            },
        },
        {
            title: 'IVR list',
            dataIndex: 'ivr_list',
            width: '50%',
            render: (_,record) => {
                return record?.ivr_list?.length > 0
                    ? record.ivr_list.map(ivr => {
                        return (
                            <>
                            <Link to={'/ivrs'} href={'/ivrs'} onClick={() => selectIvrId(ivr.id)}>{ivr.name}</Link>
                            <br />
                            </>
                        )
                    })
                    : ('')
            },
            editable: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 130,
            render: (_, record) => {
                return (
                    <>
                        <Button
                            style={{marginRight: 8}}
                            danger
                            size={'small'}
                            type="primary"
                            disabled={record.ivr_list?.length}
                            onClick={() => handleClickRemove(record.dir_key)}
                            icon={<DeleteOutlined />}
                        />
                        <Button
                            style={{marginRight: 8}}
                            size={'small'}
                            type="primary"
                            htmlType="submit"
                            onClick={() => handleClickCreate(record.dir_key)}
                            icon={<EditOutlined />}
                        />
                    </>
                )
            },
        },
    ];

    return (
        <Table
            bordered
            dataSource={data}
            loading={loading}
            columns={columns}
            pagination={false}
            rowClassName="editable-row"
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => {
                        onSelectRecordRow(record);
                    },
                };
            }}
            scroll={{x: 700, y: 500}}
        />
    );
};