import React, { useMemo, useRef, useEffect } from 'react'
import {Button, Space, Spin, Tree} from "antd";
import styled from "styled-components";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";


export default (
    {
        data,
        loading,
        currentIvr,
        isIvrEditable,
        selectedKeys,
        setFilter,
        onSelectIvr,
        onClickCreate,
    }
) => {
    const treeRef = useRef();
    const treeData = useMemo(() => {
        return data.map(item => {
            return ({
                title: item.name,
                key: item.id,
            })
        })
    }, [data]);

    useEffect(() => {
        if (selectedKeys.length) {
            treeRef.current.scrollTo({key: selectedKeys[0], align: 'top'})
        }
    }, [selectedKeys]);

    return (
        <Space
            direction="vertical"
            size={20}
            style={{width: '100%'}}
        >

            <Spin
                spinning={loading} 
            >
                <StyledTree
                    ref={treeRef}
                    height={400}
                    treeData={treeData}
                    selectedKeys={selectedKeys}
                    defaultExpandAll
                    onSelect={(selectedKey) => {
                        if (!selectedKey?.length) {
                            return;
                        }
                        onSelectIvr(selectedKey[0]);
                    }}
                />
            </Spin>

            <Button
                type="primary"
                icon={<PlusOutlined />}
                size={'large'}
                onClick={() => onClickCreate()}
                disabled={!currentIvr && isIvrEditable }
            >
                Add new
            </Button>
        </Space>
    );
};


const StyledTree = styled(Tree)`
    border: 1px solid #d9d9d9;
    min-height: 400px;
    /* overflow: scroll; */

    .ant-tree-treenode {
        border-bottom: 1px solid #f0f0f0;
        width: 100%;
        display: block;
        padding: 0;
    }

    .ant-tree-node-content-wrapper {
        display: block;
        padding: 12px 24px;
    }

    .ant-tree-switcher {
        display: none;
    }

    .ant-tree-list-scrollbar.ant-tree-list-scrollbar-show {
        display: block !important;
    }
`;