import {connect} from 'react-redux';
import Ivr from "./Ivr";
import {getIvrList, getIvrTypes, selectIvrId} from "../../actions/ivr";
import {getSoundList} from "../../actions/sounds";
import {getLangPacksList} from '../../actions/langpacks';


const mapState = ({ivr, sounds, langpacks}) => ({
    data: ivr.list,
    loading: ivr.loading,
    ivrTypes: ivr.types,
    ivrTypesRandomSoundFolders: ivr.random_sound_folders,
    ivrTypesStructuredSoundFolders: ivr.structured_sound_folders,
    ivrSoundsList: sounds.list,
    langPacksList: langpacks.list,
    selectedIvrId: ivr.selectedId
});

export default connect(mapState, {
    getIvrList,
    getIvrTypes,
    getSoundList,
    getLangPacksList,
    selectIvrId
})(Ivr);