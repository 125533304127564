import React, {useRef, useState} from 'react'
import styled, {css} from 'styled-components';
import { Modal, Input, Form } from "antd";
import { api } from '../../api/loginRoutes';
import { STRUCTURED_FOLDERS_REMOVE_API } from '../../const';


export default (
    {
        isModalRemoveVisible,
        currentFolderId,
        selectedRecordRow,
        onCancel,
        onSuccess,
    }
) => {
    let formRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [confirmationWord, setConfirmationWord] = useState("");

    const handleRemove = () => {
        if (!currentFolderId) {
            return;
        }

        setLoading(true);
        api(STRUCTURED_FOLDERS_REMOVE_API, {dir_key: currentFolderId})
            .then(r => {
                onCancel();
                onSuccess();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const confirmation = (selectedRecordRow?.ivr_list?.length > 0);
    const confirmation = true;


    return (
        <Modal
            title={`Remove structured folder`}
            visible={isModalRemoveVisible}
            confirmLoading={loading}
            onOk={() => {
                if (formRef.current) 
                    formRef.current.resetFields();

                setConfirmationWord("");
                handleRemove();
            }}
            onCancel={() => {
                if (formRef.current) 
                    formRef.current.resetFields();

                setConfirmationWord("");
                onCancel();
            }}
            okButtonProps={{ disabled: confirmation && !(confirmationWord.toLowerCase() === "yes") }}
        >
            <StyledDangerouesText confirmation={confirmation}>
                Are you sure?
            </StyledDangerouesText>

            {confirmation && <Form
                ref={formRef}
            >
                <Form.Item
                    name='confirmation'
                    label="Type 'YES' to continue"
                    rules={[
                        {
                            required: true,
                            message: 'Is required',
                        },
                    ]}
                >
                    <Input
                        onChange={(event) => {
                            const { value } = event.target;
                            setConfirmationWord(value);
                        }}
                        value={confirmationWord}
                        placeholder='YES'
                    />
                </Form.Item>
            </Form>}
        </Modal>
    );
};


const StyledDangerouesText = styled.p`
    padding-bottom: 10px;
    margin-bottom: 0;
    font-weight: 500;

    ${props => props.confirmation ? css`
        color: red;
    ` : css`
        color: inherit;
    `}
   
`;
