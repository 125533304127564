import React, {useEffect, useMemo, useState} from "react";
import BlockIvrSingleHeader from "./BlockIvrSingleHeader";
import BlockIvrSingleActionList from "./BlockIvrSingleActionList";
import {Space} from "antd";
import {generateId, getIvrActionDefaultParams} from "../../../utils";


export default (
    {
        form,
        loading,
        currentIvr,
        isIvrEditable,
        ivrTypes,
        currentActionId,
        ivrActionOrderedList,
        onIvrSubmit,
        setIvrActionOrderedList,
        setCurrentActionId,
        onClickEdit,
        onClickDiscard,
        setIsIvrChanged,
        setIsModalRemoveVisible,
        actionValidation
    }
) => {


    const handleChangeType = (currentIndex, type) => {
        const actionsMapped = ivrActionOrderedList.map((item, index) => {

            const defaultParams = currentIndex === index ? getIvrActionDefaultParams(type, ivrTypes) : {};
            const typeObject = ivrTypes.find(item => type === item.name);

            return currentIndex === index
                ? {
                    ...item,
                    action_id: type,
                    params: {...defaultParams, ...(typeObject?.sound ? {sound: item.params?.sound} : {})},
                }
                : item
        });
        setIvrActionOrderedList(actionsMapped);
    };



    const handleIncreasePosition = (index, e) => {
        // e.stopPropagation();

        if (index >= ivrActionOrderedList.length - 1) {
            return;
        }

        const listCloned = [...ivrActionOrderedList];
        const currentElement = listCloned[index];
        listCloned[index] = listCloned[index + 1];
        listCloned[index + 1] = currentElement;

        setIvrActionOrderedList(listCloned);
        setCurrentActionId(currentElement.id);
    };


    const handleDecreasePosition = (index, e) => {
        // e.stopPropagation();

        if (index === 0) {
            return;
        }

        const listCloned = [...ivrActionOrderedList];
        const currentElement = listCloned[index];
        listCloned[index] = listCloned[index - 1];
        listCloned[index - 1] = currentElement;

        setIvrActionOrderedList(listCloned);
        setCurrentActionId(currentElement.id);
    };


    const handleAddAction = (index) => {
        const listCloned = [...ivrActionOrderedList];
        const id = generateId();
        listCloned.splice(index + 1, 0, {
            id: id,
            params: {},
            action_name: null,
            action_id: null,
            sound_id: null,
        });
        setIvrActionOrderedList(listCloned);
    };


    const handleRemoveAction = (index, e) => {
        e.stopPropagation();

        const listCloned = [...ivrActionOrderedList];
        listCloned.splice(index, 1);

        const actionId = listCloned.length ? listCloned[0].id : null;

        setIvrActionOrderedList(listCloned);
        setCurrentActionId(actionId);
    };


    return (
        <Space
            size={20}
            direction={'vertical'}
            style={{width: '100%'}}
        >
            <BlockIvrSingleHeader
                form={form}
                loading={loading}
                currentIvr={currentIvr}
                isIvrEditable={isIvrEditable}
                onIvrSubmit={onIvrSubmit}
                onClickEdit={onClickEdit}
                onClickDiscard={onClickDiscard}
                setIsIvrChanged={setIsIvrChanged}
                setIsModalRemoveVisible={setIsModalRemoveVisible}
            />

            <BlockIvrSingleActionList
                ivrTypes={ivrTypes}
                isIvrEditable={isIvrEditable}
                currentActionId={currentActionId}
                setCurrentActionId={setCurrentActionId}
                actionValidation={actionValidation}
                ivrActionOrderedList={ivrActionOrderedList}
                onClickAdd={handleAddAction}
                onClickRemove={handleRemoveAction}
                onChangeType={handleChangeType}
                onClickIncrease={handleIncreasePosition}
                onClickDecrease={handleDecreasePosition}
            />
        </Space>
    )

}