import {STRUCTURED_FOLDERS as PREFIX} from '../const';
import {STRUCTURED_FOLDERS_FETCH_LIST_API /*, STRUCTURED_FOLDERS_DROPDOWN_LIST_API */} from "../const/apiMethods";
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';

// const setIvrList = createAction('SET_IVR_LIST' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);
const setStructuredFoldersList = createAction('SET_ITEMS_' + PREFIX);
const setSelectedStructuredFolderId = createAction('SET_SELECTED_ID' + PREFIX);

export const getStructuredFoldersList = (filter) => (dispatch) => {
    dispatch(setLoading(true));
    api(STRUCTURED_FOLDERS_FETCH_LIST_API, {filter})
        .then((r) => {
            if (!r || !r?.ivr_structured_sound_dir_list) {
                return;
            }

            dispatch(setStructuredFoldersList({list: r.ivr_structured_sound_dir_list}));
        })
        .finally(() => dispatch(setLoading(false)));
};

// export const getIvrFoldersList = () => (dispatch) => {
//     dispatch(setLoading(true));
//     api(STRUCTURED_FOLDERS_DROPDOWN_LIST_API)
//         .then((r) => {
//             if (!r || !r?.ivr_list) {
//                 return
//             }

//             dispatch(setIvrList({ivr_list: r.ivr_list}))
//         })
//         .finally(() => dispatch(setLoading(false)));
// }

export const selectStructuredFolderId = (id) => (dispatch) => {
    dispatch(setSelectedStructuredFolderId(id));
};
