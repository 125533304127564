import React from 'react';
import {message} from 'antd';


export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        }
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true
        })
    }

    render() {
        if (this.state.hasError) {
            message.error('Error');
            return;
        }

        return this.props.children;
    }
}