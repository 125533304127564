import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {PageContent} from "../../components/PageContent";
import {Button, PageHeader, Space} from "antd";
import FoldersFilter from "./FoldersFilter";
import FoldersTable from "./FoldersTable";
import FoldersModalCreate from "./FoldersModalCreate";
import FoldersModalRemove from './FoldersModalRemove';
import {getFileResponse} from '../../api/loginRoutes';
import {downloadDataAsFile} from '../../utils';


export default (
    {
        data,
        loading,
        selectedFolderId,
     /* ivrList, */
        selectIvrId,
     /* getIvrList, */
        getFoldersList,
     /* getFoldersDropdownList */
        selectFolderId,
    }
) => {

    const [filter, setFilter] = useState({});
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
    const [isModalRemoveVisible, setIsModalRemoveVisible] = useState(false);
    const [selectedRecordRow, onSelectRecordRow] = useState({});
    const [loadingItems, setLoadingItems] = useState({});
    const [downloadLoading, setDownloadLoading] = useState(false);

    useEffect(() => {
        getFoldersList(filter);
        selectFolderId(null);
    }, [filter]);

    const currentFolderData = useMemo(() => {
        if (!currentFolderId) {
            return {};
        }

        return data.find(item => item.dir_key === currentFolderId);
    }, [data, currentFolderId]);

    const addLoadingItems = useCallback((id) => {
        setLoadingItems(prev => ({...prev, [id]: true}));
    }, [loadingItems]);

    const removeLoadingItems = useCallback((id) => {
        setLoadingItems(prev => ({...prev, [id]: false}));
    }, [loadingItems]);

    const handleClickDownload = (id, filename) => {
        addLoadingItems(id);
        setDownloadLoading(true);
        getFileResponse('ivr_random_sound_dir:download', {dir_key: id, arch_type: 'tar-gz'}, 'file')
            .then(r => {
                if (!r) {
                    return;
                }
                // hard code - archive extension (should be accorded to what the API returns)
                downloadDataAsFile(r, filename+'.tar.gz');
            })
            .finally(() => {
                removeLoadingItems(id);
                setDownloadLoading(false);
            });
    };
    
    return (
        <PageContent>

            <PageHeader
                className="site-page-header"
                title="Folders"
            />

            <Space
                direction="vertical"
                size={20}
                style={{width: '100%'}}
            >

                <div style={{display: 'flex'}}>

                    <FoldersFilter
                        // ivrList={ivrList}
                        // getIvrList={getIvrList}
                        setFilter={setFilter}
                    />

                    <Button
                        style={{marginLeft: 'auto'}}
                        onClick={() => {
                            setCurrentFolderId(null);
                            setIsModalCreateVisible(true);
                        }}
                    >
                        Add new
                    </Button>

                </div>

                <FoldersTable
                    data={data}
                    loading={loading || downloadLoading}
                    onSelectRecordRow={onSelectRecordRow}
                    handleClickCreate={(id) => {
                        setCurrentFolderId(id);
                        setIsModalCreateVisible(true);
                    }}
                    handleClickRemove={(id) => {
                        setCurrentFolderId(id);
                        setIsModalRemoveVisible(true);
                    }}
                    handleClickDownload={handleClickDownload}
                    selectIvrId={selectIvrId}
                />

            </Space>
 
            <FoldersModalCreate
                {...{
                    // ivrList,
                    isModalCreateVisible,
                    currentFolderId,
                    currentFolderData,
                }}
                onSuccess={() => {
                    getFoldersList(filter);
                    // getFoldersDropdownList();
                }}
                onCancel={() => {
                    setCurrentFolderId(null);
                    setIsModalCreateVisible(false);
                }}
            />



            <FoldersModalRemove
                {...{
                    currentFolderId,
                    isModalRemoveVisible,
                    selectedRecordRow
                }}
                onSuccess={() => {
                    getFoldersList(filter);
                //  getFoldersDropdownList();
                }}
                onCancel={() => {
                    setCurrentFolderId(null);
                    setIsModalRemoveVisible(false);
                }}
            />

        </PageContent>
    );
};