import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {REFERENCES_SET_LISTS, REFERENCES_SET_LOADING} from '../const/actionTypes';
import {REFERENCES_FETCH_API} from '../const/apiMethods';

const setLoading = createAction(REFERENCES_SET_LOADING);
const setReferences = createAction(REFERENCES_SET_LISTS);


// GET APP REFERENCES
export const getReferences = () => (dispatch) => {
    dispatch(setLoading(true));
    api(REFERENCES_FETCH_API)
        .then((references) => {
            dispatch(setReferences(references));
        })
        .finally(() => dispatch(setLoading(false)));
};