import React, {useMemo} from 'react'
import {Form, Input, Select} from "antd";
import debounce from "lodash/debounce";
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';
import {handleFilterOption, handleFilterSort} from '../../utils';


export default (
    {
        setFilter,
        ...props
    }
) => {


    const [form] = Form.useForm();

    const handleValuesChange = async (_, params) => {
        try {
            await form.validateFields();
            setFilter(params);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    return (
        <>
            <Form
                formItemLayout={'inline'}
                layout={'inline'}
                form={form}
                onValuesChange={debounce(handleValuesChange, 500)}
            >

                <CustomFormItem
                    name={'dir_name_str'}
                    rules={[
                        {
                            max: 256,
                            message: 'The maximum is 256 characters'
                        }
                    ]}
                >
                    <Input
                        style={{minWidth: '300px'}}
                        placeholder="Folder name"
                    />
                </CustomFormItem>

            </Form>
        </>
    );
};