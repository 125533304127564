import React, {useState} from 'react'
import {Modal} from "antd";
import {api} from './../../api/loginRoutes';
import {IVR_SOUND_REMOVE_API} from '../../const';


export default (
    {
        isModalRemoveVisible,
        currentSoundId,
        currentSoundData,
        onCancel,
        onSuccess,
    }
) => {

    const [loading, setLoading] = useState(false);

    const handleRemove = () => {
        if (!currentSoundId) {
            return;
        }

        setLoading(true);
        api(IVR_SOUND_REMOVE_API, {target: {ivr_sound_id: currentSoundId}})
            .then(r => {
                onCancel();
                onSuccess();
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <Modal
            title={`Remove sound ${currentSoundData?.filename}`}
            visible={isModalRemoveVisible}
            confirmLoading={loading}
            onOk={handleRemove}
            onCancel={onCancel}
        >
            <p>Are you sure?</p>
        </Modal>
    );
};