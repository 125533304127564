import {connect} from 'react-redux';
import StructuredFolders from "./StructuredFolders";
import {getStructuredFoldersList, selectStructuredFolderId, /* getStructuredFoldersDropdownList, getIvrStructuredFoldersList  */} from '../../actions/structured_folders';
import {selectIvrId} from "../../actions/ivr";

const mapState = ({structured_folders}) => ({
    data: structured_folders.list,
    loading: structured_folders.loading,
    selectedStructuredFolderId: structured_folders.selectedId,
 /* ivrList: structured_folders.ivr_list, */
});

export default connect(mapState, {
 /* getIvrList: getIvrFoldersList, */
    selectIvrId,
    getStructuredFoldersList,
    selectStructuredFolderId,
 /* getStructuredFoldersDropdownList, */
})(StructuredFolders);
