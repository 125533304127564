import React, {useEffect, useState} from 'react'
import {Button, Form, Input, Table} from "antd";
import styled from "styled-components";
import {api} from "../../api/loginRoutes";
import {CLI_GROUP_MODIFY_API} from "../../const";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import {fixObjectParams} from '../../utils';


const EditableCell = (
    {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        validation,
        ...props
    }
) => {
    return (
        <td
            {...props}
        >
            {editing
                ? (
                    <StyledFormItem
                        name={dataIndex}
                        style={{margin: 0}}
                        rules={validation}
                    >
                        <Input />
                    </StyledFormItem>
                )
                : (
                    children
                )
            }
        </td>
    );
};


export default (
    {
        data,
        loading,
        downloadingList,
        updateData,
        onClickRemove,
        onClickDownload,
    }
) => {

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [localLoading, setLocalLoading] = useState(false);

    useEffect(() => {
        setEditingKey('');
        setLocalLoading(false);
    }, [data]);


    const isEditing = (record) => record.cg_key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            prefix_list: '',
            ...record,
        });
        setEditingKey(record.cg_key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const params = await form.validateFields();
            const paramsFixed = fixObjectParams(params, null, "");

            const r = await api(CLI_GROUP_MODIFY_API, {
                ...paramsFixed,
                cg_key: editingKey,
            });

            if (!r?.cg_key) {
                return;
            }

            updateData && updateData();
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        } finally {
            setLocalLoading(false);
        }
    };


    const columns = [
        {
            title: 'Group name',
            dataIndex: 'name',
            width: '50%',
            editable: true,
            validation: [
                {
                    required: true,
                    message: `Field group name is required`,
                }
            ]
        },
        {
            title: 'CLI list',
            dataIndex: 'prefix_list',
            width: '50%',
            editable: true,
            validation: [
                ({getFieldValue}) => ({
                    validator(_, v) {
                        const value = getFieldValue('prefix_list');
                        const numberRule = new RegExp(/^\d+([,;\t\n\s]\s*\d+)*$/m);

                        if (value && value.length && !numberRule.test(value)) {
                            return Promise.reject('Please enter correct CLI List');
                        }

                        return Promise.resolve();
                    },
                }),
            ]
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 130,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable
                    ? (
                        <span>
							<a
                                href="javascript:;"
                                onClick={() => save(record.cg_key)}
                                style={{marginRight: 8}}
                            >
								Save
							</a>

                            <a
                                href="javascript:;"
                                onClick={cancel}
                            >
                                Cancel
                            </a>
						</span>
                    )
                    : (
                        <>
                            <Button
                                style={{marginRight: 8}}
                                danger
                                size={'small'}
                                type="primary"
                                disabled={record?.cannot_be_deleted}
                                onClick={() => {
                                    onClickRemove(record.cg_key);
                                }}
                                icon={<DeleteOutlined />}
                            />
                            <Button
                                style={{marginRight: 8}}
                                size={'small'}
                                type="primary"
                                htmlType="submit"
                                disabled={editingKey !== ''}
                                icon={<EditOutlined />}
                                onClick={() => edit(record)}
                            />
                            <Button
                                size={'small'}
                                type="default"
                                loading={downloadingList.find(item => item === record.cg_key)}
                                icon={<DownloadOutlined />}
                                onClick={() => onClickDownload(record.cg_key)}
                            />
                        </>
                    );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                validation: col.validation
            }),
        };
    });

    return (
        <Form
            form={form}
            component={false}
        >
            <Table
                bordered
                dataSource={data}
                loading={loading || localLoading}
                columns={mergedColumns}
                pagination={false}
                rowClassName="editable-row"
                scroll={{x: 700, y: 500}}
                components={{
                    body: {
                        cell: EditableCell
                    }
                }}
            />
        </Form>
    );
};

const StyledFormItem = styled(Form.Item)`
    .ant-form-item-explain {
        position: absolute;
        top: 100%;
        font-size: 12px;
    }
`;