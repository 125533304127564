import React from 'react'
import {Form, Input} from 'antd';
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import debounce from "lodash/debounce";
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';


export default (
    {
        setFilter,
        ...props
    }
) => {

    const [form] = Form.useForm();

    const handleValuesChange = async (params) => {

        try {
            await form.validateFields();
            setFilter(params);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    return (
        <>
            <Form
                formItemLayout={'inline'}
                layout={'inline'}
                form={form}
                onValuesChange={debounce(handleValuesChange, 500)}
            >
                <CustomFormItem
                    name={'str'}
                >
                    <Input
                        allowClear
                        suffix={<SearchOutlined />}
                        style={{minWidth: '300px'}}
                        placeholder="Sound file, IVR"
                    />
                </CustomFormItem>
            </Form>
        </>
    );
};