import React, {useEffect} from 'react';
import history from './config/history';
import {connect} from "react-redux";
import {DEFAULT_AUTH_PATH, DEFAULT_NOT_AUTH_PATH} from "./const";
import {Router, Switch, Redirect} from "react-router-dom";
import {getPermission} from "./actions/auth";
import {getReferences} from "./actions/references";

import BaseRoute from "./components/BaseRoute";
import AuthRoute from "./components/AuthRoute";

import Ivr from "./pages/Ivr";
import Login from "./pages/Login";
import Routing from "./pages/Routing";
import Folders from "./pages/Folders";
import StructuredFolders from "./pages/StructuredFolders";
import CliGroups from "./pages/CliGroups";
import ResetPassword from './pages/ResetPassword';
import SoundsTabs from './pages/SoundsTabs/SoundsTabs';

import './App.less';

export const PermissionContext = React.createContext(null);


function App(
    {
        auth,
        permissions,
        getPermission,
        getReferences,
    }
) {

    // preload common info
    useEffect(() => {
        if (auth) {
            getPermission();
            getReferences();
        }
    }, [auth, getPermission, getReferences]);


    return (
        <PermissionContext.Provider value={permissions}>

            {/* Applesinkin: проверить redirect и history.push (multiple switches between numbers and login) */}
            <Router history={history}>
                <Switch>
                    <BaseRoute exact path="/login">
                        <Login/>
                    </BaseRoute>

                    <BaseRoute exact path="/reset-password/:token?">
                        <ResetPassword/>
                    </BaseRoute>

                    <AuthRoute exact path="/ivrs">
                        <Ivr/>
                    </AuthRoute>

                    <AuthRoute exact path="/routing">
                        <Routing/>
                    </AuthRoute>

                    <AuthRoute exact path="/cli-groups">
                        <CliGroups/>
                    </AuthRoute>

                    <AuthRoute exact path="/sounds">
                        <SoundsTabs/>
                    </AuthRoute>

                    <AuthRoute exact path="/folders">
                        <Folders/>
                    </AuthRoute>

                    <AuthRoute exact path="/structured-folders">
                        <StructuredFolders/>
                    </AuthRoute>

                    {!auth &&
                        <Redirect to={DEFAULT_NOT_AUTH_PATH}/>
                    }

                    <Redirect to={DEFAULT_AUTH_PATH}/>
                </Switch>
            </Router>

        </PermissionContext.Provider>
    );
}

const mapState = ({auth})=> ({
    auth: auth.auth,
    permissions: auth.permissions,
    maintenanceMode: auth.maintenanceMode,
});

export default connect(mapState, {
    getPermission,
    getReferences,
})(App);