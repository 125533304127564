import {FOLDERS as PREFIX} from '../const';
import {FOLDERS_FETCH_LIST_API /*, FOLDERS_DROPDOWN_LIST_API */} from "../const/apiMethods";
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';

// const setIvrList = createAction('SET_IVR_LIST' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);
const setFoldersList = createAction('SET_ITEMS_' + PREFIX);
const setSelectedFolderId = createAction('SET_SELECTED_ID' + PREFIX);

export const getFoldersList = (filter) => (dispatch) => {
    dispatch(setLoading(true));
    api(FOLDERS_FETCH_LIST_API, {filter})
        .then((r) => {
            if (!r || !r?.ivr_random_sound_dir_list) {
                return;
            }

            dispatch(setFoldersList({list: r.ivr_random_sound_dir_list}));
        })
        .finally(() => dispatch(setLoading(false)));
};

// export const getIvrFoldersList = () => (dispatch) => {
//     dispatch(setLoading(true));
//     api(FOLDERS_DROPDOWN_LIST_API)
//         .then((r) => {
//             if (!r || !r?.ivr_list) {
//                 return
//             }

//             dispatch(setIvrList({ivr_list: r.ivr_list}))
//         })
//         .finally(() => dispatch(setLoading(false)));
// }

export const selectFolderId = (id) => (dispatch) => {
    dispatch(setSelectedFolderId(id));
};
