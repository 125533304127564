import React, {useEffect, useMemo, useState} from 'react'
import {PageContent} from "../../components/PageContent";
import {Button, PageHeader, Space} from "antd";
import RoutingFilter from "./RoutingFilter";
import RoutingTable from "./RoutingTable";
import RoutingModalCreate from "./RoutingModalCreate";
import RoutingModalRemove from './RoutingModalRemove';


export default (
    {
        data,
        loading,
        ivrList,
        selectIvrId,
        selectCliGroupId,
        cliGroupsList,
        tprefixGroupList,
        destinationList,
        subdestinationList,
        getIvrList,
        getRoutingList,
        getCliGroupsList,
        getRoutingDropdownList
    }
) => {

    const [filter, setFilter] = useState({});
    const [currentRoutingId, setCurrentRoutingId] = useState(null);
    const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
    const [isModalRemoveVisible, setIsModalRemoveVisible] = useState(false);
    const [selectedRecordRow, onSelectRecordRow] = useState({});

    useEffect(() => {
        getRoutingList(filter);
    }, [filter]);


    useEffect(() => {
        getIvrList();
        getCliGroupsList();
        getRoutingDropdownList();
    }, []);


    const currentRoutingData = useMemo(() => {
        if (!currentRoutingId) {
            return {};
        }

        return data.find(item => item.rt_key === currentRoutingId);
    }, [data, currentRoutingId]);

    
    return (
        <PageContent>

            <PageHeader
                className="site-page-header"
                title="Routing"
            />

            <Space
                direction="vertical"
                size={20}
                style={{width: '100%'}}
            >

                <div style={{display: 'flex'}}>

                    <RoutingFilter
                        ivrList={ivrList}
                        cliGroupsList={cliGroupsList}
                        getIvrList={getIvrList}
                        tprefixGroupList={tprefixGroupList}
                        getCliGroupsList={getCliGroupsList}
                        setFilter={setFilter}
                    />

                    <Button
                        style={{marginLeft: 'auto'}}
                        onClick={() => {
                            setCurrentRoutingId(null);
                            setIsModalCreateVisible(true);
                        }}
                    >
                        Add new
                    </Button>

                </div>

                <RoutingTable
                    data={data}
                    loading={loading}
                    onSelectRecordRow={onSelectRecordRow}
                    handleClickCreate={(id) => {
                        setCurrentRoutingId(id);
                        setIsModalCreateVisible(true);
                    }}
                    handleClickRemove={(id) => {
                        setCurrentRoutingId(id);
                        setIsModalRemoveVisible(true);
                    }}
                    selectIvrId={selectIvrId}
                    selectCliGroupId={selectCliGroupId}
                />

            </Space>

            <RoutingModalCreate
                {...{
                    ivrList,
                    cliGroupsList,
                    isModalCreateVisible,
                    currentRoutingId,
                    currentRoutingData,
                    destinationList,
                    subdestinationList,
                }}
                onSuccess={() => {
                    getRoutingList(filter);
                    getRoutingDropdownList();
                }}
                onCancel={() => {
                    setCurrentRoutingId(null);
                    setIsModalCreateVisible(false);
                }}
            />



            <RoutingModalRemove
                {...{
                    currentRoutingId,
                    isModalRemoveVisible,
                    selectedRecordRow
                }}
                onSuccess={() => {
                    getRoutingList(filter);
                    getRoutingDropdownList();
                }}
                onCancel={() => {
                    setCurrentRoutingId(null);
                    setIsModalRemoveVisible(false);
                }}
            />

        </PageContent>
    );
};