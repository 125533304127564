import {REFERENCES_SET_LISTS, REFERENCES_SET_LOADING} from '../const/actionTypes'


const initialState = {
    inited: false,
    loading: false,
    destination_list: [],
    subdestination_list: [],
};


const handlers = {    
    [REFERENCES_SET_LOADING]: (state, {payload}) => ({
        ...state,
        loading: payload
    }),

    [REFERENCES_SET_LISTS]: (state, {payload: references}) => ({
        ...state,
        ...references,
        loading: false,
        inited: true,
    }),

    DEFAULT: state => state
};


export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};
