import {connect} from 'react-redux';
import LanguageDigitsPacks from "./LangPacks";
import {getLangPacksList} from '../../actions/langpacks';


const mapState = ({langpacks}) => ({
    data: langpacks.list,
    loading: langpacks.loading,
});

export default connect(mapState, {
    getLangPacksList
})(LanguageDigitsPacks);