import React from 'react';
import {Select} from 'antd';
import styled from 'styled-components';

export const CustomSelect = ({...params}) => {
    return (
        <StyledSelect
            {...params}
        />
    );
};

const StyledSelect = styled(Select)`
    && div.ant-select-selector {
        border-radius: 4px;
    }
    
    border-color: #d9d9d9;
    
    &&.not-valid div.ant-select-selector{
        border-color: #ff4d4f;
    }
   
`;