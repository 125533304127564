import React, {useEffect, useMemo, useState} from 'react'
import {Form, Modal, Button, Checkbox, Input, Upload, message} from "antd";
import { api, apiFormData } from '../../api/loginRoutes';
import { FOLDERS_CREATE_API, FOLDERS_MODIFY_API } from '../../const';
import {CustomFormItem} from '../../components/base/Form/CustomFormItem';
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import {clearNullData, fixObjectParams, handleFilterOption, handleFilterSort} from '../../utils';
import styled from 'styled-components';


export default (
    {
        isModalCreateVisible,
        onCancel,
        onSuccess,
        currentFolderId,
        currentFolderData,
    }
) => {


    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!currentFolderData) {
            return;
        }

        form.setFieldsValue({
            dir_key: currentFolderData?.dir_key,
            dir_name: currentFolderData?.dir_name,
            overwrite_files: false,
        });
    }, [currentFolderData]);


    const handleSubmit = (formData) => {
        const params = clearNullData(formData);

        if (fileList.length > 0) {
            const dt = new DataTransfer();
            dt.items.add(fileList[0].originFileObj);
            params['upload_file_list'] = dt.files;
        }

        if (currentFolderId) {
            params['dir_key'] = currentFolderId;
        }

        const method = !currentFolderId
            ? FOLDERS_CREATE_API
            : FOLDERS_MODIFY_API;

        setLoading(true);

        const paramsFixed = fixObjectParams(params, undefined, null);

        const dir_name = form.getFieldValue('dir_name')

        apiFormData(method, paramsFixed)
            .then(r => {
                if (!r) {
                    return;
                }
                
                console.log('r', r);
                message.success(`Folder '${dir_name}' succesfully updated`);
                onCancel();
                onSuccess();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleUploadChange = (info) => {
        setFileList(info.fileList.slice(-1));
        leaveOnlyOneParam('upload_file_list');
    };

    const leaveOnlyOneParam = (paramKey) => {
        const followingParamKeyList = ['upload_file_list', 'upload_url']
        followingParamKeyList.forEach(key => {
            if (key !== paramKey) {
                form.setFieldsValue({
                    [key]: undefined
                })
            }
        })

        if (paramKey !== 'upload_file_list') {
            setFileList([]);
        }
    }

    return (
        <Modal
            title={!currentFolderId ? "Add new sound folder" : "Edit sound folder"}
            visible={isModalCreateVisible}
            confirmLoading={loading}
            width={740}
            onOk={() => {
                form.validateFields()
                    .then((formData) => {
                        handleSubmit(formData);
                        // form.resetFields();
                        // onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            onCancel={onCancel}
            afterClose={() => {
                form.resetFields();
                setFileList([]);
            }}
        >

            <StyledForm
                form={form}
                autoComplete="off"
                labelCol={{span: 6}}
                // wrapperCol={{span: 18}}
            >
                <CustomFormItem
                    name={'dir_name'}
                    label={'Folder name'}
                    rules={[
                        {
                            required: !currentFolderId,
                            message: 'Required',
                        },
                        ({getFieldValue}) => ({
                            validator(_, v) {
                                const value = getFieldValue('dir_name');
                                const rule = new RegExp(/^\S*$/m); // w/o whitespaces

                                if ((value.length < 1 || value.length > 40) || !rule.test(value)) {
                                    return Promise.reject('Type correct folder name');
                                }
                                return Promise.resolve();
                            }
                        })

                    ]}
                >
                    <Input
                        disabled={loading || currentFolderId}
                        placeholder="Folder name"
                    />
                </CustomFormItem>

                <CustomFormItem
                    name={'upload_url'}
                    label={'URL for upload'}
                    rules={[
                        { type: 'url', message: 'Please type correct URL for upload', min: 10, max: 255,
                          //  warningOnly: true,
                        },           
                    ]}
                >
                    <Input
                        disabled={loading}
                        placeholder="Put URL for upload archive from external source"
                        onChange={() => leaveOnlyOneParam('upload_url')}
                    />
                </CustomFormItem>

                <CustomFormItem label={'or'}>
                    <Upload
                        accept={'application/x-bzip,application/x-bzip2,application/gzip,application/zip,application/vnd.rar,application/x-7z-compressed'}
                        fileList={fileList}
                        onChange={handleUploadChange}
                        beforeUpload={() => false}
                    >
                        <Button 
                            icon={<UploadOutlined />}
                            disabled={loading}
                        >
                            Click to upload from your device
                        </Button>
                    </Upload>
                </CustomFormItem>

                {currentFolderId && (
                <CustomFormItem
                    name={'overwrite_files'}
                >
                    <Checkbox
                        disabled={loading}
                        defaultChecked={false}
                        onChange={(ev) => {
                            const checked = ev.target.checked;
                            form.setFieldsValue({
                                overwrite_files: checked,
                            });
                            // ev.target.value = checked;
                        }}
                    >
                        Overwrite existed files
                    </Checkbox>
                </CustomFormItem>
                )}

            </StyledForm>

        </Modal>
    );
};

const StyledForm = styled(Form)`
    && .ant-row.ant-form-item > .ant-col.ant-form-item-label {
        text-align: left;

        max-width: 110px;
    }
    
`;
