import {connect} from 'react-redux';
import Routing from "./Routing";
import {getIvrRoutingList, getRoutingDropdownList, getRoutingList} from '../../actions/routing';
import {selectIvrId} from "../../actions/ivr";
import {getCliGroupsList, selectCliGroupId} from "../../actions/cli_groups";

const mapState = ({routing, references}) => ({
    data: routing.list,
    tprefixGroupList: routing.tprefix_list,
    loading: routing.loading,
    ivrList: routing.ivr_list,
    cliGroupsList: routing.cligroup_list,
    destinationList: references.destination_list,
    subdestinationList: references.subdestination_list,
});

export default connect(mapState, {
    getIvrList: getIvrRoutingList,
    selectIvrId,
    selectCliGroupId,
    getRoutingList,
    getCliGroupsList,
    getRoutingDropdownList,
})(Routing);