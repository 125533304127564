import React, {useState} from 'react'
import {Modal} from "antd";
import {api} from 'api/loginRoutes';
import {CLI_GROUP_REMOVE_API} from 'const';


export default (
    {
        isModalRemoveVisible,
        currentCliGroupId,
        onCancel,
        onSuccess,
    }
) => {

    const [loading, setLoading] = useState(false);

    const handleRemove = () => {
        if (!currentCliGroupId) {
            return;
        }

        setLoading(true);
        api(CLI_GROUP_REMOVE_API, {cg_key: currentCliGroupId})
            .then(r => {
                onCancel();
                onSuccess();
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <Modal
            title={`Remove routing`}
            visible={isModalRemoveVisible}
            confirmLoading={loading}
            onOk={handleRemove}
            onCancel={onCancel}
        >
            <p>Are you sure?</p>
        </Modal>
    );
};