// References
export const REFERENCES_FETCH_API = 'references';


// IVR
export const IVR_LIST_API = 'ivr:get_list';
export const IVR_CREATE_API = 'ivr:create';
export const IVR_MODIFY_API = 'ivr:modify';
export const IVR_REMOVE_API = 'ivr:remove';
export const IVR_ACTION_TYPE_LIST_API = 'ivr:get_ivr_action_type_list';


// Cli Groups
export const CLI_GROUP_FETCH_LIST_API = 'cligroup:get_list';
export const CLI_GROUP_FETCH_API = 'cligroup:get';
export const CLI_GROUP_CREATE_API = 'cligroup:create';
export const CLI_GROUP_MODIFY_API = 'cligroup:modify';
export const CLI_GROUP_REMOVE_API = 'cligroup:remove';


// Routing
export const ROUTING_FETCH_LIST_API = 'routing:get_list';
export const ROUTING_CREATE_API = 'routing:create';
export const ROUTING_MODIFY_API = 'routing:modify';
export const ROUTING_REMOVE_API = 'routing:remove';
export const ROUTING_DROPDOWN_LIST_API = 'routing:dropdown_get_list';

// Folders
export const FOLDERS_FETCH_LIST_API = 'ivr_random_sound_dir:get_list';
export const FOLDERS_CREATE_API = 'ivr_random_sound_dir:create';
export const FOLDERS_MODIFY_API = 'ivr_random_sound_dir:modify';
export const FOLDERS_REMOVE_API = 'ivr_random_sound_dir:remove';
// export const FOLDERS_DROPDOWN_LIST_API = 'ivr_random_sound_dir:dropdown_get_list';

// Structured Folders
export const STRUCTURED_FOLDERS_FETCH_LIST_API = 'ivr_structured_sound_dir:get_list';
export const STRUCTURED_FOLDERS_CREATE_API = 'ivr_structured_sound_dir:create';
export const STRUCTURED_FOLDERS_MODIFY_API = 'ivr_structured_sound_dir:modify';
export const STRUCTURED_FOLDERS_REMOVE_API = 'ivr_structured_sound_dir:remove';
export const STRUCTURED_FOLDERS_DROPDOWN_LIST_API = 'ivr_structured_sound_dir:dropdown_get_list';

// Sounds
export const IVR_SOUND_FETCH_LIST_API = 'ivr_sound:get_list';
export const IVR_SOUND_DOWNLOAD_API = 'ivr_sound:download';
export const IVR_SOUND_CREATE_API = 'ivr_sound:create';
export const IVR_SOUND_MODIFY_API = 'ivr_sound:modify';
export const IVR_SOUND_REMOVE_API = 'ivr_sound:remove';


// Language pack
export const LANGPACK_FETCH_LIST_API = 'langpack:get_list';
export const LANGPACK_CREATE_API = 'langpack:create';
export const LANGPACK_MODIFY_API = 'langpack:modify';
export const LANGPACK_REMOVE_API = 'langpack:remove';


// IVR
export const IVR_LISTEN_API = 'ivr:listen';