import React, {useEffect, useMemo, useState} from 'react';
import {Col, PageHeader, Row} from 'antd';
import {PageContent} from 'components/PageContent';
import LangPacksSingleData from './LangPacksSingleData';
import LangPacksList from './LangPacksList';


export default (
    {
        data,
        loading,
        getLangPacksList,
    }
) => {

    const [filter, setFilter] = useState({});
    const [currentPackId, setCurrentPackId] = useState(null);
    const [isNewCurrentPack, setIsNewCurrentPack] = useState(false);
    const [changedSoundList, setChangedSoundList] = useState(false);

    
    useEffect(() => {
        getLangPacksList();
    }, []);

    const langPackData = useMemo(() => {
        return data.filter(lang => {
            return !filter.str || lang.langpack_name.includes(filter.str)
        })
    }, [data, filter]);

    const onSuccess = async () => {
        return await getLangPacksList();
    };

    const handleSetCurrentPackId = (val) => {
        setIsNewCurrentPack(false);
        setCurrentPackId(val);
    };

    const handleSetIsNewCurrentPack = () => {
        setIsNewCurrentPack(true);
        setCurrentPackId(null);
    };

    const currentPackData = useMemo(() => {
        if (!currentPackId || !data) {
            return null;
        }

        return data.find(item => item.lp_key === currentPackId);
    }, [data, currentPackId]);


    return (
        <>

            <Row gutter={20}>
                
                <Col span={5}>

                    <LangPacksList
                        data={langPackData}
                        selectedKeys={currentPackId ? [currentPackId] : []}
                        loading={loading}
                        setFilter={setFilter}
                        currentPackId={currentPackId}
                        changedSoundList={changedSoundList}
                        setChangedSoundList={setChangedSoundList}
                        setCurrentPackId={handleSetCurrentPackId}
                        setIsNewCurrentPack={handleSetIsNewCurrentPack}
                    />

                </Col>

                <Col span={18}>

                    <LangPacksSingleData
                        isNewCurrentPack={isNewCurrentPack}
                        setCurrentPackId={handleSetCurrentPackId}
                        currentPackData={currentPackData}
                        setChangedSoundList={setChangedSoundList}
                        onSuccess={onSuccess}
                    />

                </Col>

            </Row>

        </>
    );
}