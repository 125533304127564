import React from 'react'
import {Button, Table} from "antd";
import {Link} from "react-router-dom";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";


export default (
    {
        data,
        loading,
        onSelectRecordRow,
        selectIvrId,
        selectCliGroupId,
        handleClickCreate,
        handleClickRemove,
    }
) => {

    const columns = [
        {
            title: 'Tech prefix',
            dataIndex: 'rt_tprefix',
            width: '50%',
            editable: true,
        },
        {
            title: 'CLI group',
            dataIndex: 'cligroup_name',
            width: '50%',
            render: (_, {cligroup_name}) => {
                return cligroup_name === "ANY" 
                    ? <>{cligroup_name}</> 
                    : <Link to={'/cli-groups'} href={'/cli-groups'} onClick={() => selectCliGroupId(cligroup_name)}>{cligroup_name}</Link>
            },
            editable: true,
        },
        {
            title: 'Destination/Subdestination/Prefix',
            dataIndex: 'destination_name',
            render: (_, {destination_name, subdestination_name, rt_prefix}) => [destination_name, subdestination_name, rt_prefix].filter(item => !!item).join('/'),
            width: '50%',
            editable: true,
        },
        {
            title: 'IVR',
            dataIndex: 'ivr_name',
            width: '50%',
            render: (_, {ivr_id, ivr_name}) => (
                <Link to={'/ivrs'} href={'/ivrs'} onClick={() => selectIvrId(ivr_id)}>{ivr_name}</Link>
            ),
            editable: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 130,
            render: (_, record) => {
                return (
                    <>
                        <Button
                            style={{marginRight: 8}}
                            danger
                            size={'small'}
                            type="primary"
                            disabled={record?.cannot_be_deleted}
                            onClick={() => handleClickRemove(record.rt_key)}
                            icon={<DeleteOutlined />}
                        />
                        <Button
                            style={{marginRight: 8}}
                            size={'small'}
                            type="primary"
                            htmlType="submit"
                            onClick={() => handleClickCreate(record.rt_key)}
                            icon={<EditOutlined />}
                        />
                    </>
                )
            },
        },
    ];

    return (
        <Table
            bordered
            dataSource={data}
            loading={loading}
            columns={columns}
            pagination={false}
            rowClassName="editable-row"
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => {
                        onSelectRecordRow(record);
                    },
                };
            }}
            scroll={{x: 700, y: 500}}
        />
    );
};