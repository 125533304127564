import {ROUTING as PREFIX} from '../const/';
import {ROUTING_FETCH_LIST_API, ROUTING_DROPDOWN_LIST_API} from "../const/apiMethods";
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {SET_GROUP_ROUTING} from '../const';

const setIvrList = createAction('SET_IVR_LIST' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);
const setRoutingList = createAction('SET_ITEMS_' + PREFIX);
const setRoutingGroupList = createAction(SET_GROUP_ROUTING);

export const getRoutingList = (filter) => (dispatch) => {
    dispatch(setLoading(true));
    api(ROUTING_FETCH_LIST_API, {filter})
        .then((r) => {
            if (!r || !r?.routing_list) {
                return;
            }

            dispatch(setRoutingList({list: r.routing_list}));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const getIvrRoutingList = () => (dispatch) => {
    dispatch(setLoading(true));
    api('routing:dropdown_get_list')
        .then((r) => {
            if (!r || !r?.ivr_list) {
                return
            }

            dispatch(setIvrList({ivr_list: r.ivr_list}))
        })
        .finally(() => dispatch(setLoading(false)));
}

export const getRoutingDropdownList = () => (dispatch) => {
    dispatch(setLoading(true));
    api(ROUTING_DROPDOWN_LIST_API)
        .then((r) => {
            if (!r || (!r?.ivr_list && !r?.tprefix_list && !r?.cligroup_list)) {
                return;
            }

            dispatch(setRoutingGroupList({
                ivr_list: r.ivr_list || [],
                tprefix_list: r.tprefix_list || [],
                cligroup_list: r.cligroup_list || []
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};