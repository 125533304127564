import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import './config/Alert'
import App from './App';
import 'antd/dist/antd.less';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import packageJson from '../package.json';


export const APP_VERSION = packageJson.version;

ReactDOM.render(
  <React.StrictMode>

    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
