import {CLI_GROUPS as PREFIX} from '../const/';
import {CLI_GROUP_FETCH_LIST_API} from "../const/apiMethods";
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';

const setLoading = createAction('SET_LOADING_' + PREFIX);
const setCliGroupsList = createAction('SET_ITEMS_' + PREFIX);
const setSelectedCliGroupId = createAction('SET_SELECTED_ID' + PREFIX);

export const getCliGroupsList = (filter) => (dispatch) => {
    dispatch(setLoading(true));
    api(CLI_GROUP_FETCH_LIST_API, {filter})
        .then((r) => {
            if (!r || !r?.cligroup_list) {
                return;
            }

            dispatch(setCliGroupsList({list: r.cligroup_list}));
        })
        .finally(() => dispatch(setLoading(false)));
};


export const selectCliGroupId = (id) => (dispatch) => {
    dispatch(setSelectedCliGroupId(id));
};