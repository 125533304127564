import React, {useMemo, useState} from 'react';
import {Button, Space, Spin, Tree, Modal} from 'antd';
import LangPacksFilter from './LangPacksFilter';
import styled from "styled-components";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";


export default (
    {
        data,
        loading,
        setFilter,
        selectedKeys,
        currentPackId,
        changedSoundList,
        setCurrentPackId,
        setChangedSoundList,
        setIsNewCurrentPack,
    }
) => {

    const [showChangeLangPackModal, setShowChangeLangPackModal] = useState(false);
    const [selectedKeyState, setSelectedKeyState] = useState(null);

    const treeData = useMemo(() => {
        return data.map(item => ({
            key: item.lp_key,
            title: item.langpack_name,
        }));
    }, [data]);


    return (
        <>
            <Space
                direction="vertical"
                size={20}
                style={{width: '100%'}}
            >
                
                <LangPacksFilter
                    setFilter={setFilter}
                />

                <Spin
                    spinning={loading} 
                >
                    <StyledTree
                        defaultExpandAll
                        height={400}
                        selectedKeys={selectedKeys}
                        treeData={treeData}
                        onSelect={(selectedKey) => {
                            if (!selectedKey?.length) {
                                return;
                            }
                            if (currentPackId && changedSoundList) {
                                setShowChangeLangPackModal(true);
                                setSelectedKeyState(selectedKey[0]);
                            } else {
                                setCurrentPackId(selectedKey[0]);
                            }
                        }}
                    />
                </Spin>

                <Button 
                    type="primary"
                    icon={<PlusOutlined />}
                    size={'large'}
                    onClick={() => setIsNewCurrentPack(true)}
                >
                    Add new
                </Button>

            </Space>
            <Modal
                title={`Discard changes`}
                visible={showChangeLangPackModal}
                confirmLoading={loading}
                onOk={() => {
                    setShowChangeLangPackModal(false);
                    setCurrentPackId(selectedKeyState);
                    setChangedSoundList(false);
                }}
                onCancel={() => {
                    setShowChangeLangPackModal(false);
                }}
            >
                <p>Are you sure?</p>
            </Modal>
        </>
    );
}


const StyledTree = styled(Tree)`
    border: 1px solid #d9d9d9;
    min-height: 400px;

    .ant-tree-treenode {
        border-bottom: 1px solid #f0f0f0;
        width: 100%;
        display: block;
        padding: 0;
    }

    .ant-tree-node-content-wrapper {
        display: block;
        padding: 12px 24px;
    }

    .ant-tree-switcher {
        display: none;
    }

`;