import React, {useEffect, useState} from 'react'
import {Button, Modal, Upload} from "antd";
import {api} from 'api/loginRoutes';
import {IVR_SOUND_CREATE_API, IVR_SOUND_MODIFY_API} from 'const';
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import {apiFormData} from "../../api/loginRoutes";


export default (
    {
        currentSoundId,
        currentSoundData,
        isModalCreateVisible,
        onCancel,
        onSuccess,
    }
) => {


    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (!currentSoundData) {
            return;
        }
    }, [currentSoundData]);


    const handleSubmit = () => {
        const dt = new DataTransfer();
        dt.items.add(fileList[0].originFileObj);
        
        const params = {
            ...(currentSoundId ? {target: {ivr_sound_id: currentSoundId}} : {}),
            upload_file_list: dt.files
        };

        if (currentSoundId) {
            params['id'] = currentSoundId;
        }
        
        const method = !currentSoundId
            ? IVR_SOUND_CREATE_API
            : IVR_SOUND_MODIFY_API;

        setLoading(true);
        apiFormData(method, params)
            .then(r => {
                console.log('r', r);
                onCancel();
                onSuccess();
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleChange = (info) => {
        setFileList(info.fileList.slice(-1));
    };


    return (
        <Modal
            title={"Add new Sound"}
            visible={isModalCreateVisible}
            confirmLoading={loading}
            onOk={handleSubmit}
            onCancel={onCancel}
            afterClose={() => {
                setFileList([]);
            }}
        >

            <Upload
                accept={'audio/mpeg,audio/wave,audio/wav,audio/x-wav,audio/x-pn-wav'}
                fileList={fileList}
                onChange={handleChange}
                beforeUpload={() => false}
            >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>

        </Modal>
    );
};