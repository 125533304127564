import React from 'react';
import styled from "styled-components";
import {Form} from "antd";

export const CustomFormItem = ({style, children, ...props}) => {

    return (
        <StyledFormItem
            style={style}
            {...props}
        >
            {children}
        </StyledFormItem>

    )
};

const StyledFormItem = styled(Form.Item)`
    && span.ant-input-affix-wrapper, && div.ant-input-number, && div.ant-select-selector, && input.ant-input {
        border-radius: 4px;
    }
    &&.percent_input {
        margin-bottom: 0;
    }
    .reset-password {
        margin-left: 10px;
    }
`;