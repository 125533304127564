import {Tabs} from "antd";
import {PageContent} from "components/PageContent";
import LangPacks from "../../pages/LangPacks";
import Sounds from "../../pages/Sounds";
import React, { useState } from "react";

export default () => {

    const [tab, setTab] = useState("sounds");

    return (
        <PageContent>

            <Tabs
                defaultActiveKey={tab}
                onChange={val => setTab(val)}
            >
                <Tabs.TabPane tab="Sounds" key="sounds"></Tabs.TabPane>
                <Tabs.TabPane tab="Language packs" key="language-packs"></Tabs.TabPane>
            </Tabs>

            {tab === 'sounds' &&
                <Sounds />
            }

            {tab === 'language-packs' &&
                <LangPacks />
            }

        </PageContent>
    )
}