import React, {useMemo} from 'react';
import ErrorBoundary from "./Error";
import {Button, Layout, Menu, Space} from "antd";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import styled from "styled-components";
import LoginOutlined from "@ant-design/icons/lib/icons/LoginOutlined";
import {connect} from "react-redux";
import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";
import {logoutUser} from "../actions/auth";


const {Header, Content} = Layout;


const BaseLayout = (
    {
        auth,
        logoutUser,
        children,
    }
) => {

    const location = useLocation();

    const selectedItem = useMemo(() => {
        return [location.pathname];
    }, [location]);


    return (
        <Layout
            className="layout"
            style={{minHeight: '100vh'}}
        >
            <StyledHeader
                // style={{backgroundColor: "#ffffff"}}
            >
                <div className="logo" style={{color: '#ffffff'}}>IVR constructor</div>

                {auth &&
                    <Menu
                        theme={'dark'}
                        mode="horizontal"
                        selectedKeys={selectedItem}
                        disabledOverflow
                    >
                        <Menu.Item key={'/ivrs'}>
                            <Link to={'/ivrs'} href={'/ivrs'}>IVRs</Link>
                        </Menu.Item>
                        <Menu.Item key={'/routing'}>
                            <Link to={'/routing'} href={'/routing'}>Routing</Link>
                        </Menu.Item>
                        <Menu.Item key={'/cli-groups'}>
                            <Link to={'/cli-groups'} href={'/cli-groups'}>CLI groups</Link>
                        </Menu.Item>
                        <Menu.Item key={'/sounds'}>
                            <Link to={'/sounds'} href={'/sounds'}>Sounds</Link>
                        </Menu.Item>
                        <Menu.Item key={'/folders'}>
                            <Link to={'/folders'} href={'/folders'}>Sound folders</Link>
                        </Menu.Item>
                        <Menu.Item key={'/structured-folders'}>
                            <Link to={'/structured-folders'} href={'/structured-folders'}>Structured folders</Link>
                        </Menu.Item>
                    </Menu>
                }

                <Menu
                    theme={'dark'}
                    mode="horizontal"
                    style={{marginLeft: 'auto'}}
                    disabledOverflow
                >
                    {!auth
                        ? (
                            <Menu.Item
                                icon={<LoginOutlined/>}
                                key={'/login'}
                            >
                                <Link
                                    to={'/login'}
                                    href={'/login'}
                                >
                                    Login
                                </Link>
                            </Menu.Item>
                        )
                        : (
                            <Menu.Item
                                icon={<LogoutOutlined/>}
                                onClick={logoutUser}
                                key={'/logout'}
                            >
                                Logout
                            </Menu.Item>
                        )
                    }
                </Menu>

            </StyledHeader>
            <StyledContent>
                <ErrorBoundary>
                    {children}
                </ErrorBoundary>
            </StyledContent>
        </Layout>
    );
}


const StyledHeader = styled(Header)`
    display: flex;
    
    .logo {
        margin-right: 40px;
        font-size: 20px;
    }
`;

const StyledContent = styled(Content)`
    padding: 0 50px 30px;
`;

const mapState = ({auth})=> ({
    auth: auth.auth,
});

export default connect(mapState, {
    logoutUser
})(BaseLayout);