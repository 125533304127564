import React from "react";
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import styled from "styled-components";
import "./audio-player-style.css";


export default React.forwardRef((
    {
        ...props
    }
, ref) => {

    return (
        <StyledAudioPlayer
            ref={ref}
            preload={'none'}
            className="ivr-audio__content"
            autoPlayAfterSrcChange={false}
            autoPlay={false}
            layout="horizontal-reverse"
            showJumpControls={false}
            customProgressBarSection={[
                RHAP_UI.CURRENT_TIME,
                RHAP_UI.PROGRESS_BAR,
                RHAP_UI.DURATION,
            ]}
            customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
            {...props}
        />
    )
});


const StyledAudioPlayer = styled(AudioPlayer)`
    padding: 2px 15px;
    max-width: 800px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #f3f3f3;

    .rhap_controls-section {
        flex-grow: 0;
    }

    .rhap_time {
        font-size: 14px;
    }

    .rhap_play-pause-button {
        font-size: 35px;
        width: 35px;
        height: 35px;
        margin-left: 0;
        
        svg {
            vertical-align: top;
        }
    }
`;