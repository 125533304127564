import {
    SET_AUTH_DATA,
    SET_AUTH_LOADING,
    SET_USER_DATA,
    SET_MAINTENANCE_MODE,
    
    SET_PERMISSION,
    SET_TWO_FACTOR_AUTH,

    DEFAULT_AUTH_PATH,
    DEFAULT_NOT_AUTH_PATH,
    DEFAULT_ERROR_MESSAGE,
} from '../const';

import {
    LOCAL_STORAGE_USER_DATA,
    LOCAL_STORAGE_API_KEY
} from '../const/localStorageKeys';

import {login, logout} from '../api/auth';
import {createAction} from  './defaults';
import {setRoles} from './roles';
import history from '../config/history';
import {api} from "../api/loginRoutes";
import {message} from "antd";


export const setAuth = (payload) => ({type: SET_AUTH_DATA, payload});
const setLoading = createAction(SET_AUTH_LOADING);
const setUserData = createAction(SET_USER_DATA);
const setSecondAuthType = createAction(SET_TWO_FACTOR_AUTH);
const setMaintenanceModeAction = createAction(SET_MAINTENANCE_MODE);



export const setMaintenanceMode = (value) => (dispatch) => {
    dispatch( setMaintenanceModeAction(value) );
    if (value) {
        localStorage.removeItem(LOCAL_STORAGE_API_KEY);
        localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
        dispatch( setAuth(false) );
        dispatch( setUserData(null) );
    }
};


export const loginUser = (data) => (dispatch) => {
    dispatch(setLoading(true));
    return login(data)
        .then((response) => {

            if (!response) {
                return;
            }

            const result = response?.data?.result || {};
            const secondAuthType = result.second_authentication_type;

            if (secondAuthType) {
                dispatch(setSecondAuthType({
                    secondAuthType: secondAuthType === 1
                        ? 'email'
                        : 'phone',
                    secondAuthLink: secondAuthType === 1
                        ? result.email
                        : result.phone,
                    secondAuthEmail: result.email
                }));
                return;
            }

            const sessionSite = result?.session?.session_key;

            if (!sessionSite) {

                if (result?.reason) {
                    message.error(result.reason);
                }

                return;
            }

            // save data in LocalStorage
            localStorage.setItem(LOCAL_STORAGE_API_KEY, result?.session?.session_key);
            localStorage.setItem(LOCAL_STORAGE_USER_DATA, JSON.stringify(result));

            dispatch( setAuth(true) );
            dispatch( setRoles(result?.session?.account_user?.role_list) );
            dispatch( setUserData(result) );

            history.push(DEFAULT_AUTH_PATH);
            return response;

        })
        .catch((error) => {
            dispatch(setAuth(false));

            const reasonCode = error?.response?.data?.error?.reason_code;
            if (!reasonCode) {
                message.error(error?.response?.data?.error?.data?.error_type || DEFAULT_ERROR_MESSAGE);
            }

            return error;

        })
        .finally(() => {
            dispatch(setLoading(false));
        })
};


export const getPermission = () => (dispatch) => {
    api('permitted_methods').then((r) => {
        dispatch({
            type: SET_PERMISSION,
            payload: r
        })
    });
};


export const logoutUser = () => async (dispatch) => {
    await logout();

    localStorage.removeItem(LOCAL_STORAGE_API_KEY);
    localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
    dispatch( setAuth(false) );
    dispatch( setUserData(null) );

    history.push(DEFAULT_NOT_AUTH_PATH);
};


export const ping = () => async (dispatch) => {
    const res = await api('ping');

    if (res instanceof Error || !res.ip)
        return false;

    dispatch( setMaintenanceModeAction(false) );
    return true;
};