import {checkVersionAxios, requestAxios, requestAxiosWithHeaders, requestFormDataAxios} from '.';
import {responseErrorToString} from "../utils";
import axios from "axios";
import {APP_VERSION} from "../index";
import {message} from "antd";


export const api = (method = '', params = {}, processErrorManual, cancelToken, withHeaders = false) => {
    const paramsCloned = {...params};
    const config = {
        headers: {"X-App-Version": 'atx_admin/' + APP_VERSION}
    };

    // cancelToken as f argument is deprecated. use params property "cancelToken" instead
    if (cancelToken) {
        config.cancelToken = cancelToken.token;
    }

    if (paramsCloned.cancelToken) {
        config.cancelToken = paramsCloned.cancelToken.token;
        delete paramsCloned.cancelToken;
    }

    const requestApi = withHeaders ? requestAxiosWithHeaders : requestAxios;
    return requestApi.post('',
        {
            jsonrpc: "2.0",
            method,
            params: paramsCloned,
            id: null
        },
        config
    )
    .then(response => {
        if (axios.isCancel(response))
            return {...response, cancelled: true};

        // const result = _.get(response, 'data.result', {});
        const result = response?.data?.result || {};

        if ( result.reason_code && method.lastIndexOf("allocation:") === 0 ) {
            result.hash = response.data.hash;
        }

        return (response && response.data && response.data.result) || {}
    })
    .catch((error) => {
        if (error && !error.response) {
            return error;
        }
        if (processErrorManual) {
            return error.response.data;
        } else {
            if ( error.response && error.response.data.error.reason_code && method.lastIndexOf("allocation:") === 0 ) {
                const data = error.response.data.error;
                return {
                    ...data,
                    hash: error.response.data.hash
                };
            }
            if (!error.response || error.response.status !== 503) {
                message.error(responseErrorToString(error.response.data.error, error.response.status));
            }
        }
    })
};


export const apiFormData = (method = '', params = {}, processErrorManual) => {
    const paramsCloned = {...params};
    return requestFormDataAxios.post("",
        {
            jsonrpc: "2.0",
            method,
            params: paramsCloned,
            id: null
        }
    )
    .then(response => {
        const result = response?.data?.result || {};

        if ( result.reason_code && method.lastIndexOf("incorporated:") === 0 ) {
            result.hash = response.data.hash;
        }

        return result
    })
    .catch((error) => {
        if (error && !error.response) {
            return error;
        }
        if (processErrorManual) {
            return error.response.data;
        } else {
            if (!error.response || error.response.status !== 503) {
                message.error(responseErrorToString(error.response.data.error, error.response.status));
            }
        }
    })
};


export const generateApiUrl = ({method, path = ''}) => {
    return `${process.env.REACT_APP_API_URL}${path}?method=${method}`
};


export const getCsvResponse = (method, params) => {
    return requestAxios.post(
        generateApiUrl({method, path: '/csv'}),
        {
            jsonrpc: '2.0',
            method,
            params,
            id: null,
        },
        {
            responseType: 'text/csv',
            headers: {
                "X-App-Version": 'atx_admin/' + APP_VERSION,
            }
        }

    )
    .then( response => (response && response.data) )
    .catch( e => null )
};


export const getFileResponse = (method, params, type = 'file', processErrorManual) => {
    return requestAxios.post(
        generateApiUrl({method, path: '/' + type}),
        {
            jsonrpc: '2.0',
            method,
            params,
            id: null,
        },
        {
            responseType: 'blob',
            headers: {
                "X-App-Version": 'atx_admin/' + APP_VERSION,
            }
        }
    )
    .then( response => (response && response.data) )
    .catch((err) => {
        if (err && !err.response) {
            return err;
        }

        // err.response.data is a Blob object (due post param 'response': 'blob')
        const blb = err?.response?.data;
        const reader = new FileReader();

        reader.addEventListener('loadend', (e) => {
            const error = JSON.parse(e.target.result);
            console.log(error);

            if (processErrorManual) {
                return error;
            } else {
                if (!err.response || err.response.status !== 503) {
                    message.error(responseErrorToString(error.error, err.response.status));
                }
            }  
        });

        reader.readAsText(blb);
    })
};


export const apiWithHeaders = (method, params) => {
    return requestAxiosWithHeaders.post(``,
        {
            jsonrpc: '2.0',
            params,
            method,
            id: null
        },
        {headers: {
                "X-App-Version": 'atx_client/' + APP_VERSION,
                httpAgent: 123
            }})
        .then(response => (response && response.data && response.data.result) || {})
        .catch(e => e)
};


export const getAppVersion = () => {
    return checkVersionAxios.get(`/meta.json?timestamp=${Date.now()}`)
        .then( (response) => response && response.data && response.data.version
            ? response.data
            : null
        )
        .catch( e => null )
};
