import React, {useEffect, useState} from "react";
import IvrListener from "../../../components/IvrListener/IvrListener";
import styled from "styled-components";
import {Button, Form, Input, Typography} from "antd";
import {CustomFormItem} from "../../../components/base/Form/CustomFormItem";

const {Title} = Typography;

export default (
    {
        form,
        loading,
        currentIvr,
        isIvrEditable,
        onIvrSubmit,
        onClickEdit,
        onClickDiscard,
        setIsIvrChanged,
        setIsModalRemoveVisible,
    }
) => {


    return (
        <StyledHeader>

            <StyledHeaderItem>
                {isIvrEditable
                    ? (
                        <Form 
                            form={form}
                            initialValues={{
                                name: currentIvr?.name
                            }}
                            onValuesChange={() => setIsIvrChanged(true)}
                        >
                            <CustomFormItem
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Is required',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 300}}
                                    placeholder="Enter IVR name"
                                    disabled={loading}
                                />
                            </CustomFormItem>
                        </Form>
                    )
                    : (
                        <Title level={5}>{currentIvr?.name}</Title>
                    )
                }
            </StyledHeaderItem>

            {currentIvr && (
                <StyledHeaderItem style={{display: 'flex'}}>
                    <IvrListener
                        ivrId={currentIvr ? currentIvr.id : null}
                    />

                    {!isIvrEditable
                        ? (
                            <Button
                                size={'large'}
                                type={'primary'}
                                onClick={onClickEdit}
                                disabled={loading}
                            >
                                Edit
                            </Button>
                        )
                        : (
                            <>
                                <Button
                                    size={'large'}
                                    type={'primary'}
                                    onClick={onIvrSubmit}
                                    loading={loading}
                                >
                                    Apply changes
                                </Button>
                                <Button
                                    danger
                                    size={'large'}
                                    type={'default'}
                                    style={{marginLeft: 10}}
                                    onClick={onClickDiscard}
                                    disabled={loading}
                                >
                                    Discard changes
                                </Button>
                            </>
                        )
                    }

                    <Button
                        danger
                        size={'large'}
                        type={'primary'}
                        onClick={() => setIsModalRemoveVisible(true)}
                        style={{marginLeft: 10}}
                        disabled={loading || currentIvr?.cannot_be_deleted}
                    >
                        Delete
                    </Button>
                </StyledHeaderItem>
            )}

            {!currentIvr && isIvrEditable && (
                <StyledHeaderItem>
                    <Button
                        size={'large'}
                        type={'primary'}
                        loading={loading}
                        onClick={onIvrSubmit}
                    >
                        Create IVR
                    </Button>
                    <Button
                        danger
                        size={'large'}
                        type={'default'}
                        style={{marginLeft: 10}}
                        disabled={loading}
                        onClick={onClickDiscard}
                    >
                        Discard changes
                    </Button>
                </StyledHeaderItem>
            )}

        </StyledHeader>
    )

}


const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledHeaderItem = styled.div`
    .rs-btn {
        margin-left: 20px;
    }
`;